import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from 'formik';
import { Box, Card, Grid, Spinner, Text } from "theme-ui";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Heading,
  TextInput,
  NumberInput,
  Form,
  Button,
  SingleSelect,
  DataList,
  Card as LumosCard
} from "@loanpal/lumos__core";
import { object, string } from "yup";
import logo from "../../images/goodleap-white-orange.svg";
import Loading from "../../utils/loading";
import {
  phone,
  phoneDigits,
  numberToMoney,
  numberToMoneyNoDecimal,
  moneyStringToNumber,
  validateMoneyInput
} from "../../utils/format";
import { default as ErrorPage } from "../../utils/error";
import { default as NotFoundPage } from "../../utils/not-found";
import { notifyAdminErrorMessage } from "../../utils/api";
import gtag, { install } from "ga-gtag";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from '@aws-amplify/ui-react';

// api
import { API, graphqlOperation } from "aws-amplify";
import { getSharedApp } from "../../graphql/queries";
import { createGoodLinks } from "../../graphql/mutations";

import HOME_IMPROVEMENT from "../../images/HI_1.png";
import ROOF from "../../images/ROOF_1.png";
import SOLAR from "../../images/SOLAR_1.png";
import BATTERY from "../../images/BATTERY_1.png";

//payment factors
const pfstandard = require('../../utils/paymentFactorsStandard.json');
const pfnonoipif = require('../../utils/paymentFactorsNoNoIPIF.json');
const pfnonono = require('../../utils/paymentFactorsNoNoNo.json');
const pfnonoipifminpayment = require('../../utils/paymentFactorsNoNoIPIFMinPayment.json')

const Home = () => {
  const [token, setToken] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notFound, setNotFound] = useState(false);
  const { id, client, integrator } = useParams();
  const [SharedApp, setSharedApp] = useState();
  const [payment, setPayment] = useState();
  const [displayResult, setDisplayResult] = useState(true);
  const [states, setStates] = useState();
  const [statesItems, setStatesItems] = useState([]);
  const [goodLink, setGoodLink] = useState();
  const [canApply, setCanApply] = useState(false);
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [limitations, setLimitations] = useState();
  const [offers, setOffers] = useState();
  const [offerItems, setOfferItems] = useState([])
  const [singleOffer, setSingleOffer] = useState("");
  const [paymentAmountObj, setPaymentAmountObj] = useState({});
  const [singleState, setSingleState] = useState("");
  const [offerDesc, setOfferDesc] = useState();
  const [offerName, setOfferName] = useState();
  const [calculatorType, setCalculatorType] = useState(false);
  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const captchaRef = useRef(null);
  const [timer, setTimer] = useState(null);

const sandbox_promotionId = "2345f0aa-db7a-42b9-807b-9e8a82b81ae3";
const prod_promotionId =  "26d09475-32ec-448c-bb43-9f70aea55644";
const host = window.location.host;
// Change environment dev/prod accordingly
const env = "prod";
const version = "2.0";

  useEffect(() => {
    fetchSharedApp(id);
    // eslint-disable-next-line
  }, [id]);

 useEffect(() => {
    if (token !== "") {
      window.location.href = token;
    }
  }, [token])

  useEffect(() => {
      if (paymentAmountObj.payment) {
        getPayment(singleOffer, paymentAmountObj.paymentAmount, paymentAmountObj.monthly);
      }
    // eslint-disable-next-line
  }, [paymentAmountObj])

  useEffect(() => {
    if (SharedApp) {
      setPaymentAmountObj({ paymentAmount: SharedApp.paymentAmount, loanAmount: 0, payment: false, monthly: calculatorType });
      getOffers(SharedApp.clientId, SharedApp.channel, SharedApp.paymentAmount, calculatorType);
      getCategories(SharedApp.clientId, SharedApp.category);
    }
    // eslint-disable-next-line
  }, [SharedApp]);

  useEffect( () => {
    try {
      if (goodLink){
        API.graphql(
          graphqlOperation(createGoodLinks, { input: goodLink })
        );
      }
    } catch (err) {
      console.log("error fetching Shared Apps");
    }
  }, [goodLink]);

  useEffect(() => {
    if (error && errorMessage !== "") {
      notifyAdminErrorMessage(SharedApp, id, errorMessage);
    }
    // eslint-disable-next-line
  }, [error, errorMessage]);

const getPromotionId = () => {
  if (host.includes("sandox") || (env === "dev"))  {
    return sandbox_promotionId;
  } else  {
    return prod_promotionId;
  }
}

const productItems = [
    { label: 'Loan product', text: offerDesc},
    {
      label: 'Term and rate',
      text: offerName,
    }
  ];

const estimatedPayment = payment ? payment[0].calculations.map((pay) => ({
    label: pay.paymentDescription,
    text: <div>
          {loadingPayment ? (
            <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
              <Text variant="h3" as="h2">
                $ <Skeleton width={125} height={24} />
              </Text>
            </SkeletonTheme>
          ) : (
            <Text variant="h3" as="h2">
              {numberToMoney(pay.amount.value)}
              <span style={styles.period} color="neutral.base">
              &nbsp;{pay.interval === "MONTHLY" ? "/ month" : ""}
              </span>
            </Text>
          )}
          </div>,
  })) : {};


  const getPaymentFactor = (offerId, soffers) => {
    const offer = soffers ? soffers.find(({id}) => id === offerId ) : offers.find(({id}) => id === offerId );
    // console.log((offer.rate/100).toFixed(4), offer.term, offer);
    if (offer.productCode === 'STANDARD_PI') {
      const pf = pfstandard.find((({ Term, Rate }) => Term === offer.term && Rate.toString() === (offer.rate/100).toFixed(4)));
      return pf.Standard;
    } else if (offer.productCode.includes("DEFERRED_PI_IF_PAID")) {
      // console.log(offer.term, offer.productCode, offer.rate/100, offer.productPromotionalPeriod.duration);
      const pf = pfnonoipif.find((({ IntroPeriod, Term, Rate }) => IntroPeriod === offer.productPromotionalPeriod.duration && Term === offer.term && Rate === offer.rate/100 ));
      return pf.Standard;
    } else if (offer.productCode.includes("DEFERRED_PI")) {
      // console.log(offer.term, offer.productCode, offer.rate/100, offer.productPromotionalPeriod.duration);
      const pf = pfnonono.find((({ IntroPeriod, Term, Rate }) => IntroPeriod === offer.productPromotionalPeriod.duration && Term === offer.term && Rate === offer.rate/100 ));
      return pf.Standard;
    } else if (offer.productCode.includes("DEFERRED_INT_IF_PAID_MIN_PMT")) {
      // console.log(offer.term, offer.productCode, offer.rate/100, offer.productPromotionalPeriod.duration);
      const pf = pfnonoipifminpayment.find((({ IntroPeriod, Term, Rate }) => IntroPeriod === offer.productPromotionalPeriod.duration && Term === offer.term && Rate === offer.rate/100 ));
      return pf.Standard;
    }
  }

  const calculateLoanAmount = (amt, paymentFactor) => {
    setPaymentAmountObj({ paymentAmount: amt, loanAmount: (amt / paymentFactor).toFixed(0), payment: false, monthly: calculatorType });
    return (amt / paymentFactor).toFixed(0);
  }

  const getPayment = async (offerId, paymentAmount, monthly, offers) => {
    setLoadingPayment(true);
    let loanAmount = 0;
    if (monthly) {
      const pf = getPaymentFactor(offerId, offers);
      loanAmount = calculateLoanAmount(paymentAmount, pf);
    }
    if (offerId === "" || loanAmount === "" || loanAmount <= minAmount || loanAmount > maxAmount || !validateMoneyInput(loanAmount)) {
      //TODO Throw errors if loanAmount is pass max or under min
      return;
    }
    try {
      const clientId = SharedApp.clientId;
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          promotionId: getPromotionId(),
          amount: loanAmount,
          offerId: offerId
        },
      };
      // get payment
      await API.get("developerApi", "/payments", myInit0)
      .then((response) => {
        setPayment(response);
        setLoadingPayment(false);
      },
      (err) => {
        setErrorMessage("Error getting payments" + err);
        setError(true);
        console.log("Error getting payments", err);
        setLoadingPayment(false);
      })

    } catch (err) {
      console.log("error getting payments");
      setLoadingPayment(false);
      return false;
    }
  };

  const getCategories = async (clientId, category) => {
    try {
    const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      // get categories
      await API.get("developerApi", "/categories", myInit0)
      .then((response) => {
        const cat = response.find(el => el.name === getCategoryName(category).toUpperCase());
        getLimitations(clientId, cat.id);
        setMinAmount(cat.minLoanAmount);
        setMaxAmount(cat.maxLoanAmount);
      },
      (err) => {
        setErrorMessage("Error getting categories" + err);
        setError(true);
        console.log("Error getting categories", err);
        setLoadingPayment(false);
      })
    } catch (err) {
      console.log("error getting categories", err);
      return false;
    }
  }

    const getLimitations = async (clientId, category) => {
    try {
    const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };
      // get categories
      await API.get("developerApi", "/limitations", myInit0)
      .then((response) => {
        const limitations = response.data.filter((element) => element.categoryId === category)
        setLimitations(limitations);
      },
      (err) => {
        setErrorMessage("Error getting limitations" + err);
        setError(true);
        console.log("Error getting limitations", err);
        setLoadingPayment(false);
      })
    } catch (err) {
      console.log("error getting limitations", err);
      return false;
    }
  }

    const getOffers = async (clientId, channel, paymentAmount, monthly) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          promotionId: getPromotionId(),
          channel: channel
        },
      };

      // get offers
      await API.get("developerApi", "/offers", myInit0)
      .then((response) => {
        if (response.data.length > 0) {
          setOffers(response.data);
          let offersArrayOptions = [];
          offersArrayOptions = response.data
            .map((element) => [
              ...offersArrayOptions,
              { name: element.productCode.includes("DEFERRED") ? `${element.productName} - ${element.name}` : `${element.name} - ${element.productName}`, value: element.id },
            ])
            .flat();
          setOfferItems(offersArrayOptions);
          if (response.data.length === 1) {
            setSingleOffer(response.data[0].id);
            setOfferDesc(response.data[0].productDescription);
            setOfferName(response.data[0].name);
            setStates(JSON.stringify(response.data[0].states));
            if (response.data[0].states.length === 1) {
              setSingleState(response.data[0].states[0]);
            }
            let stateArrayOptions = [];
            stateArrayOptions = response.data[0].states
              .map((element) => [
                ...stateArrayOptions,
                { name: element, value: element },
              ])
              .flat();
            setStatesItems(stateArrayOptions);
            getPayment(response.data[0].id, paymentAmount, monthly, response.data);
          }
        } else {
          setErrorMessage("Error getting offers, no offers returned");
          setError(true);
          console.log("Error getting offers, no offers returned");
        }
      },
      (err) => {
         setErrorMessage("Error getting offers " + err);
        setError(true);
        console.log("Error getting offers", err);
      })

    } catch (err) {
      console.log("error getting Offers");
      return false;
    }
  };

  async function fetchSharedApp(id) {
    try {
      const appData = await API.graphql(
        graphqlOperation(getSharedApp, { id: id })
      );
      const { clientId: cId, status: st } = appData.data.getSharedApp;
      const displayStatuses = ["ACTIVE", "TEST"];
      if (cId.toLowerCase() !== client || displayStatuses.indexOf(st) < 0) {
        setLoading(false);
        setNotFound(true);
        return null;
      }
      setSharedApp(appData.data.getSharedApp);
      setCalculatorType(!!(appData.data.getSharedApp.calculatorType && appData.data.getSharedApp.calculatorType === "PA"));
      // analytics tracking
      install("G-KBMPQSEG5M", {
        page_title: `GoodLeap GoodLink ${appData.data.getSharedApp.clientId}`,
        page_location: `https://${window.location.host}/apply/${id}`,
      });
      gtag("set", "clientId", appData.data.getSharedApp.clientId);
      gtag("event", "page_view");
      setDisplayResult(JSON.parse(appData.data.getSharedApp.displayIframe));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setNotFound(true);
      console.log("error fetching SharedApp", err);
    }
  }

  const handleClick = async (payload) => {
    try {
      console.log(payload);
      setSpinner(true);
      const myInit = {
        body: { data: payload || {} },
        queryStringParameters: {
          apiId: `${SharedApp.clientId}ApiId`,
          apiKey: `${SharedApp.clientId}ApiKey`,
          clientId: SharedApp.clientId
        },
      };
      const data = await API.post("developerApi", "/loans-share", myInit);
      setIframeLoading(true);
      setTimeout(() => setIframeLoading(false), 5000);
      setToken(data.link);
      if (data.link !== "") setSpinner(false);
    } catch (err) {
      setSpinner(false);
      setErrorMessage("Error creating Shared App " + payload);
      setError(true);
      console.log("error creating Shared App", err);
    }
  };

  const handleCaptchaChange = async(value) => {
    setSpinner(true);
    const myValues = {
        queryStringParameters: {
          response: value
       },
      };
      const response = await API.post("developerApi", "/siteverify", myValues);
      if (response.success) {
        setCanApply(true);
      } else {
        setCanApply(false);
      }
      setSpinner(false);
  }

  const buildGoodLink = (values) => {
    if (values.email.includes("@goodleap.com")) return null;
    const gLink = {
      clientId: SharedApp.clientId,
      custFirstName: values.firstName,
      custLastName: values.lastName,
      custEmail: values.email,
      custMobile: values.mobile,
      custState: values.state,
      custLoanAmount: values.loanAmount,
      emailSent: SharedApp.sendEmail,
    };
    setGoodLink(gLink);
  }

  const getReferenceId = (refId, mobile, lastName) => {
    return refId === "lastName" ? lastName
           : refId === "mobilePhone" ? phoneDigits(mobile)
           : refId === "quickLink" ? "QuickLink"
           : refId === "integratorId" ? integrator ? integrator : phoneDigits(mobile)
           : phoneDigits(mobile)
  }

  const buildPaydown = (values) => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.state ||
      !values.mobile ||
      !values.offer
    ) {
      return;
    }
    buildGoodLink(values);
    const {
      referenceId,
      offer,
      channel,
      state,
      email,
      firstName,
      lastName,
      mobile,
      salesRepEmail,
      salesRepFirstName,
      salesRepLastName,
      salesRepPhone,
      loanAmount,
      autopayIntent,
      sendEmail,
      integratorId,
      redirectUrl
    } = values;
    return JSON.stringify({
      referenceNumber: getReferenceId(referenceId, mobile, lastName, integratorId),
      offerId: offer,
      channel: channel,
      state: state,
      applicant: {
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
      submittingUser: {
        firstName: salesRepFirstName,
        lastName: salesRepLastName,
        email: salesRepEmail,
        phone: salesRepPhone,
      },
      amount: {
        value: loanAmount,
        type: "USD",
      },
      sendEmail: sendEmail,
      enrollments: [autopayIntent ? "AUTOPAY" : ""],
      decisionPageOptions: {
        "redirectUrl": redirectUrl
      }
    });
  };

  const getCategory = (category) => {
    if (category === "HOME_IMPROVEMENT") return HOME_IMPROVEMENT
    else if (category === "ROOF") return ROOF;
    else if (category === "SOLAR") return SOLAR;
    else if (category === "BATTERY") return BATTERY;
  }

  const getCategoryName = (category) => {
    if (category === "HOME_IMPROVEMENT") return "Home Improvement";
    else if (category === "ROOF") return "Roofing / Windows / Doors / Siding / Geothermal";
    else if (category === "SOLAR") return "Solar";
    else if (category === "BATTERY") return "Stand-Alone Battery";
  }

  const StatesInput = () => {
    const { setFieldError, setErrors, values } = useFormikContext();
    return (
      <>
      <SingleSelect
        name="state"
        label={"State"}
        size={20}
        items={statesItems}
        dangerouslyOnChange={(target) => {
          if(values.paymentAmount !== "") {
            setLoadingPayment(true);
            setErrors({});
            const amt = moneyStringToNumber(values.paymentAmount);
            if (limitations.find(el => el.state === target)) {
              const limits = limitations.find(el => el.state === target);
              const pf = getPaymentFactor(singleOffer);
              // console.log(calculateLoanAmount(amt, pf));
              if (calculateLoanAmount(amt, pf) < limits.minLoanAmount) {
                setFieldError("paymentAmount", `Calculated loan amount needs to be greater than ${numberToMoneyNoDecimal(limits.minLoanAmount)}`);
                setCanApply(false);
              } else if (calculateLoanAmount(amt, pf) > limits.maxLoanAmount) {
                setFieldError("paymentAmount", `Calculated loan amount needs to be less than ${numberToMoneyNoDecimal(limits.maxLoanAmount)}`);
                setCanApply(false);
              } else {
                setLoadingPayment(false);
                setCanApply(true);
              }
            } else {
              const pf = getPaymentFactor(singleOffer);
              if (calculateLoanAmount(amt, pf) < minAmount) {
                setFieldError("paymentAmount", `Calculated loan amount needs to be greater than ${numberToMoneyNoDecimal(minAmount)}`);
                setCanApply(false);
              } else if (calculateLoanAmount(amt, pf) > maxAmount) {
                setFieldError("paymentAmount", `Calculated loan amount needs to be less than ${numberToMoneyNoDecimal(maxAmount)}`);
                setCanApply(false);
              } else {
                setLoadingPayment(false);
                setCanApply(true);
              }
            }
          }
        }}
      />
      </>
    )
  }

  const AmountInput = () => {
    const { setFieldValue, setFieldError, setErrors, values } = useFormikContext();
    return (
      <>
      <NumberInput
          key="paymentAmount"
          label="What is your budget for a monthly payment?"
          name="paymentAmount"
          autoComplete="off"
          autoFocus="autoFocus"
          onBlurCapture={({ target }) => {
            const moneyValue = moneyStringToNumber(target.value);
            if (Number.isNaN(moneyValue)) return;
            setFieldValue('paymentAmount', numberToMoney(moneyValue));
          }}
          onChangeCapture = {({target}) => {
            setLoadingPayment(true);
            const moneyValue = moneyStringToNumber(target.value);
            const amt = target.value;
            setPaymentAmountObj({ paymentAmount: moneyStringToNumber(target.value), loanAmount: 0, payment: false, monthly: calculatorType });
            setFieldValue('paymentAmount', numberToMoney(moneyValue));
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
              if (singleOffer) {
                setErrors({});
                if (limitations.find(el => el.state === values.state)) {
                  const pf = getPaymentFactor(singleOffer);
                  // console.log(pf);
                  const limits = limitations.find(el => el.state === values.state);
                  if (moneyStringToNumber(calculateLoanAmount(amt, pf)) < limits.minLoanAmount) {
                    setFieldError("paymentAmount", `Calculated loan amount needs to be greater than ${numberToMoneyNoDecimal(limits.minLoanAmount)}`);
                    setCanApply(false);
                  } else if (moneyStringToNumber(calculateLoanAmount(amt, pf)) > maxAmount) {
                    setFieldError("paymentAmount", `Calculated loan amount needs to be less than ${numberToMoneyNoDecimal(limits.maxLoanAmount)}`);
                    setCanApply(false);
                  } else {
                    setPaymentAmountObj({ paymentAmount: moneyStringToNumber(amt), loanAmount: moneyStringToNumber(calculateLoanAmount(amt, pf)), payment: true, monthly: calculatorType });
                    setCanApply(true);
                  }
                } else {
                  const pf = getPaymentFactor(singleOffer);
                  if (moneyStringToNumber(calculateLoanAmount(amt, pf)) < minAmount) {
                    setFieldError("paymentAmount", `Calculated loan amount needs to be greater than ${numberToMoneyNoDecimal(minAmount)}`);
                    setCanApply(false);
                  } else if (moneyStringToNumber(calculateLoanAmount(amt, pf)) > maxAmount) {
                    setFieldError("paymentAmount", `Calculated loan amount needs to be less than ${numberToMoneyNoDecimal(maxAmount)}`);
                    setCanApply(false);
                  } else {
                    setPaymentAmountObj({ paymentAmount: moneyStringToNumber(amt), loanAmount: moneyStringToNumber(calculateLoanAmount(amt, pf)), payment: true, monthly: calculatorType });
                    setCanApply(true);
                  }
                }
              }
            }, 2000)
            setTimer(newTimer)
          }}
        />
      </>
    )
};

  return (
    <>
      {error && <ErrorPage />}
      {notFound && <NotFoundPage />}
      {loading && <Loading />}
      {token !== "" && displayResult && !loading && !error && !notFound && (
        <div className="container">
          {iframeLoading && <Loading />}
          {/* <iframe
            title="GoodLeap QuickLink"
            className="iframe"
            allowFullScreen
            src={token}
            name="Quicklink"
          /> */}
        </div>
      )}
      {token !== "" && !displayResult && !loading && !error && !notFound && (
        <Box
          sx={{
            maxWidth: "100%",
            mx: "auto",
          }}
        >
          <Box>
            <Heading
              className="heading-box"
              sx={{
                width: "960px",
                margin: "0 auto",
                backgroundColor: "#003439",
              }}
            >
              <img src={logo} alt="GoodLeap" style={styles.logo} />
            </Heading>
          </Box>
          <Box sx={{ pl: 6, pr: 6, pt: 4, width: ["960px", "640px", "480px"] }}>
            <>
              <Heading variant="h2">
                Thank you for your interest in GoodLeap financing.
              </Heading>
              <Box sx={{ pt: 2, fontSize: "18px" }}>
                An application form has been sent to your email address.
                <br />
                <br />
                If you have questions, reach out to{" "}
                <Text sx={{ fontWeight: 800 }}>
                  {SharedApp.submittingUser.firstName}{" "}
                  {SharedApp.submittingUser.lastName}
                </Text>{" "}
                at{" "}
                <Text sx={{ fontWeight: 800 }}>
                  {phone(SharedApp.submittingUser.phone)}
                </Text>{" "}
                or{" "}
                <Text sx={{ fontWeight: 800 }}>
                  {SharedApp.submittingUser.email}
                </Text>
                .
              </Box>
            </>
          </Box>
        </Box>
      )}
      {token === "" && !loading && !error && !notFound && (
        <Box
          sx={{
            maxWidth: "100%",
            mx: "auto",
          }}
        >
          <Box>
            <Heading
              className="heading-box"
              sx={{
                width: "960px",
                margin: "0 auto",
                backgroundColor: "#003439",
              }}
            >
              <img
                src={logo}
                alt="GoodLeap"
                style={styles.logo}
                className="logo"
              />
            </Heading>
          </Box>
          {!offers && (
            <Form>
            <Card
              sx={{
                width: 960,
                margin: "0 auto",
              }}
              className="card-form"
            >
              <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                <Heading variant="h5">
                  Credit Application Sample: Complete this form to see an ESTIMATED payment scenario. Use the $ amount suggested by our company representative.
                </Heading>
                <Box as="p" sx={{ mb: 2 }}>
                  &nbsp;
                </Box>
                <Box sx={{ width: "100%", mb: 2, mt: 2  }}>
                  <Loader
                      variation="linear"
                      emptyColor="#ffffff"
                      filledColor="#F58320"
                      className="loader"
                      />
                </Box>
                <Box>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="firstName"
                      label="First name"
                      autoComplete="off"
                      readOnly
                    />
                    <TextInput
                      name="lastName"
                      label="Last name"
                      autoComplete="off"
                      readOnly
                    />
                  </Grid>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="email"
                      label="Email address"
                      autoComplete="off"
                      type="email"
                      readOnly
                    />
                    <TextInput
                      name="mobile"
                      label="Mobile number"
                      autoComplete="off"
                      type="phone"
                      description="Please enter 10 digit mobile number"
                      readOnly
                    />
                  </Grid>
                   <Grid columns={[1, 2]} gap={[0, 3]}>
                      <>
                       <Box sx={{ width: "100%", mr: 5, mt: 2 }}>
                        <SingleSelect
                          name="offer"
                          label={"Getting offers, one moment..."}
                          readOnly
                          items={[]}
                        />
                      </Box>
                      <Box sx={{ width: "30%", mr: 5 }}>
                      <SingleSelect
                        name="state"
                        label={"State"}
                        readOnly
                        size={20}
                        items={[]}
                      />
                      </Box>
                    </>
                  </Grid>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <Box sx={{ mt: 2 }}>
                      <TextInput
                        name="loanAmount"
                        label="Amount to finance"
                        autoComplete="off"
                        type="loanAmount"
                        readOnly
                      />
                     </Box>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    my: 3,
                  }}
                >
                <Button type="submit" sx={{ mt: 3, px: 5 }} disabled>
                  APPLY NOW
                </Button>
                </Box>
              </Box>
            </Card>
            </Form>
          )}
          {offers && (
          <Form
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              mobile: "",
              state: singleState,
              offer: singleOffer || "",
              channel: SharedApp ? SharedApp.channel : "",
              referenceId: SharedApp ? SharedApp.referenceId : "",
              loanAmount: 0,
              paymentAmount: SharedApp && numberToMoney(SharedApp.paymentAmount),
              autopayIntent: true,
              sendEmail: SharedApp ? SharedApp.sendEmail : false,
              salesRepFirstName: SharedApp
                ? SharedApp.submittingUser.firstName
                : "",
              salesRepLastName: SharedApp
                ? SharedApp.submittingUser.lastName
                : "",
              salesRepEmail: SharedApp ? SharedApp.submittingUser.email : "",
              salesRepPhone:
                SharedApp && SharedApp.submittingUser.phone
                  ? SharedApp.submittingUser.phone
                  : "",
              integratorId: SharedApp.integratorId,
              redirectUrl: SharedApp.redirectUrl
            }}
            enableReinitialize={false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={object({
              firstName: string().required(`Enter your first name`),
              lastName: string().required(`Enter your last name`),
              email: string()
                .email("Enter a valid email address, like j.doe@example.com")
                .required(`Enter the borrower's email address`),
              state: string().required(`Select state`),
              offer: string().required('Select an Offer'),
              mobile: string()
                .matches(phoneRegExp, "Enter a valid phone number")
                .required(`Enter a phone number`),
              paymentAmount: string()
                        .required("Required")
                        .test("currenty", `Please enter a currency value`, val => validateMoneyInput(val))
            })}
            onSubmit={({ ...values }) => {
              values.loanAmount = paymentAmountObj.loanAmount;
              console.log("onSubmit", values);
              //TODO  Validate here the loanAmount not the payment value
              handleClick(buildPaydown(values));
            }}
            showSummary={false}
          >
            <Card
              sx={{
                width: 960,
                margin: "0 auto",
              }}
              className="card-form"
            >
              <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                <Heading variant="h5">
                  Complete this form to see an ESTIMATED payment scenario. Use the $ payment suggested by our company representative.
                </Heading>
                <Box as="p" sx={{ mb: 2 }}>
                  &nbsp;
                </Box>
                <Box>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="firstName"
                      label="First name"
                      autoComplete="off"
                    />
                    <TextInput
                      name="lastName"
                      label="Last name"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <TextInput
                      name="email"
                      label="Email address"
                      autoComplete="off"
                      type="email"
                    />
                    <TextInput
                      name="mobile"
                      label="Mobile number"
                      autoComplete="off"
                      type="phone"
                      description="Please enter 10 digit mobile number"
                    />
                  </Grid>
                  {!offers && (
                  <Box sx={{ width: "100%", mb: 2, mt: 2  }}>
                  <Loader
                      variation="linear"
                      emptyColor="#ffffff"
                      filledColor="#F58320"
                      className="loader"
                      />
                   </Box>
                   )}
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <Box sx={{ width: "100%", mr: 5, mt: 2 }}>
                      <SingleSelect
                        dangerouslyOnChange={(e) => {
                          setSingleOffer(e);
                          console.log(e);
                          const offer = offers.find(({id}) => id === e )
                          if (offer) {
                            setOfferDesc(offer.productDescription);
                            setOfferName(offer.name);
                            getPayment(e, paymentAmountObj.paymentAmount, paymentAmountObj.monthly, null);
                            setStates(JSON.stringify(offer.states));
                            let stateArrayOptions = [];
                            stateArrayOptions = offer.states
                              .map((element) => [
                                ...stateArrayOptions,
                                { name: element, value: element },
                              ])
                              .flat();
                            setStatesItems(stateArrayOptions);
                            if (offer.states.length === 1) {
                              setSingleState(offer.states[0]);
                            }
                          }
                        }}
                        name="offer"
                        label={`Offer`}
                        items={offerItems}
                      />
                      {/* <Text sx={{position: "relative", top: "-15px"}} variant="small">{offerDesc}</Text> */}
                    </Box>
                    {!states && (
                      <Box sx={{ width: "30%", mr: 5, mt: 2 }}>
                       <SingleSelect
                        name="state"
                        label={"State"}
                        readOnly
                        description="Select an offer first"
                        size={20}
                        items={[]}
                      />
                      </Box>
                    )}
                    {states && (
                    <Box sx={{ width: "30%", mr: 5, mt: 2 }}>
                      <StatesInput />
                    </Box>
                  )}
                  </Grid>
                  <Grid columns={[1, 2]} gap={[0, 3]}>
                    <Box sx={{ mt: 2 }}>
                      <AmountInput />
                     </Box>
                  </Grid>
                </Box>
                {payment && version === "2.0" &&
                <LumosCard
                    header={{
                      title: (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img src={getCategory(SharedApp.category)} alt={getCategoryName(SharedApp.category)} style={styles.catLogo} />
                          {getCategoryName(SharedApp.category)} estimated loan details
                        </Box>
                      ),
                    }}
                  >
                <Box
                sx={{
                    pt: 3,
                    mt: 3,
                    borderTop: "1px solid",
                    borderTopColor: "neutral.l3",
                  }}>
                  <Grid columns={[1, 1, '1fr 2fr 2fr']}>
                    <Box
                      sx={{
                        borderRight: '1px solid',
                        borderColor: ['transparent', 'transparent', 'neutral.l3'],
                        mb: 3,
                      }}
                    >
                      <div>Loan amount</div>
                      <Text variant="h3" as="h2">
                        {numberToMoney(paymentAmountObj.loanAmount)}
                      </Text>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid',
                        borderColor: ['transparent', 'transparent', 'neutral.l3'],
                        mb: 3,
                        pr: 2,
                      }}
                    >
                      <DataList columns={1} items={productItems} />
                    </Box>
                    <Box sx={{ mb: 3 }}>
                      <DataList columns={1} items={estimatedPayment} />
                    </Box>
                  </Grid>
                </Box>
                </LumosCard>}
                <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    pt: 3,
                    my: 3,
                    borderTop: "1px solid",
                    borderTopColor: "neutral.l3",
                  }}>
                   <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6Ld6Kb8hAAAAADN-z4PFwBU-mBk8HcGaWkKtmSs-"
                    onChange={handleCaptchaChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    my: 3,
                  }}
                >
                  {spinner && (
                    <>
                      <Button type="submit" sx={{ mt: 3, px: 5 }} disabled>
                        APPLY NOW
                      </Button>
                      <Spinner
                        title="loading"
                        size="32"
                        sx={{ mt: 3, px: 3, color: "#FF8300" }}
                      />
                    </>
                  )}
                  {!spinner && canApply && (
                    <Button type="submit" sx={{ mt: 3, px: 5 }}>
                      APPLY NOW
                    </Button>
                  )}
                  {!spinner && !canApply && (
                    <Button type="submit" sx={{ mt: 3, px: 5 }} disabled>
                      APPLY NOW
                    </Button>
                  )}
                </Box>
              </Box>
            </Card>
          </Form>
          )}
        </Box>
      )}
    </>
  );
};

const styles = {
  logo: {
    width: 150,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 4,
  },
  heading: {
    backgroundColor: "#003439",
  },
  loader: {
    height: "5rem"
  },
  period: {
     display: 'inline', fontSize: '70%', fontWeight: 'normal'
  },
  catLogo: {
    width: 24,
    position: "relative",
    top: 0,
    margin: "0 10px",
  }
};

export default Home;
