import React, { useEffect, useState, useRef } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { reqsByStatusSorted, appsByClientId } from "../../graphql/queries";
import { deleteRequests, updateRequests } from "../../graphql/mutations";
import { searchRequestss } from "../../graphql/custom-queries";
import HOME_IMPROVEMENT from "../../images/HI_1.png";
import ROOF from "../../images/ROOF_1.png";
import SOLAR from "../../images/SOLAR_1.png";
import BATTERY from "../../images/BATTERY_1.png";
import CORP from "../../images/CORP_1.png";
import PLUS from "../../images/PLUS_1.png";
import BLANK from "../../images/BLANK_1.png";
import { BiChevronRight, BiChevronLeft, BiChevronsLeft} from "react-icons/bi";
import {
  Heading,
  Button as LumosButton,
  AlertInline,
  Modal,
  ModalControls,
  useNotify,
  Icon,
  Badge,
  ChoiceInput,
  Form as LumosForm,
  TextInput
 } from "@loanpal/lumos__core";
 import { object, array, string } from "yup";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import { Status, StatusVariant  } from "../../utils/status";
import {
  formatDateTime
} from "../../utils/format";
import {
  email
} from "../../utils/formValidation";

import {
  Authenticator,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SearchField,
  View,
  Flex,
  Menu,
  MenuItem,
  SelectField
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { updateNotesRequest } from "../../utils/api";

import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const Requests = () => {
  const [Requests, setRequests] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const [isOpenNotes, toggleOpenNotes] = useState(false);
  const [isOpenComms, toggleOpenComms] = useState(false);
  const [notesMessage, setNotesMessage] = useState();
  const [requestId, setRequestId] = useState();
  const [accountName, setAccountName] = useState();
  const [request, setRequest] = useState();
  const { addSnack } = useNotify();
  const inputRef = useRef(null);

  const [requestCount, setRequestsCount] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousToken] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  const hasNext = !!nextNextToken && previousTokens.length < totalPages - 1
  const hasPrev = previousTokens.length

  let navigate = useNavigate();

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line
  }, [nextToken, pageSize]);

  async function fetchRequests() {
    try {
      setSpinner(true);
      localStorage.removeItem('requestsFilterByStatus');
      const reqData = await API.graphql(
        graphqlOperation(searchRequestss, {
        sort: {
          field: 'updatedAt',
          direction: 'desc'
        },
        limit: pageSize,
        nextToken
      }));
      if (reqData.data.searchRequestss.items.length > 0) {
        const reqs = reqData.data.searchRequestss.items;
        const pages = Math.ceil(reqData.data.searchRequestss.total/pageSize);
        if (reqs && reqs.length > 0) {
          setRequests([...reqs]);
          setRequestsCount(reqData.data.searchRequestss.total);
          setNextNextToken(reqData.data.searchRequestss.nextToken);
          setTotalPages(pages);
        }
      }
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log("error fetching Requests", err);
    }
  }

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e, 10));
  };

  const lookForNewUsers = (notes) => {
    // Create a regular expression pattern
    const pattern = /###(.*?)###/g;

    // Use the pattern with the String.matchAll() method
    const matches = Array.from(notes.matchAll(pattern));
  // Extract the substrings between the markers
    const substrings = matches.map(match => match[1]);
    if (substrings.length > 0) {
      return(substrings[substrings.length-1].trim());
    } else {
      return "";
    }
  }

 const handleSubmitSendQuickLinkEmail = async (request, emails) => {
  const sharedApp = await fetchSharedApp(request.clientId);
  const newUser = lookForNewUsers(request.notes);
  if (sharedApp) {
    const myParams = {
        queryStringParameters: {
          accountName: request.accountName,
          quicklink: sharedApp.formUrl,
          emails: emails.join(),
          newUser: newUser
        },
      };

     await API.post("developerApi", "/sendEmailWithQuickLink", myParams);
     const app = buildApp(request, request.status);
      updateNotesRequest(app, "Complete Step email sent to: " + emails.join(", "))
      addSnack({ variant: 'success', message: 'Email sent succesffully!' });
      toggleOpenComms(false);
  }
 }

  async function fetchSharedApp(id) {
    console.log(id);
    if (!id && id === "") return;
    try {
      const appData = await API.graphql(
        graphqlOperation(appsByClientId, { clientId: id })
      );
      console.log(appData);
      const app = appData.data.appsByClientId.items;
      if (app && app.length > 0) {
        return app[0]
      } else {
        console.log("error Shared App doesn't exist or can't be found");
        addSnack({ variant: 'danger', message: `Couldn't find Shared App to send email!` });
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
      addSnack({ variant: 'danger', message: `Error sending QuickLink email!` });
    }
  }

 async function listRequestsByStatus(st) {
  try {
        if (st === "ALL")  { fetchRequests(); return; }
        setSpinner(true);
        const appData = await API.graphql(graphqlOperation(reqsByStatusSorted, { status: st , limit: pageSize }, nextToken));
        const pages = Math.ceil(appData.data.reqsByStatusSorted.total/pageSize);
        const reqs = appData.data.reqsByStatusSorted.items;
        if (reqs) {
          setRequests([...reqs]);
          setRequestsCount(appData.data.reqsByStatusSorted.items.length);
          setNextNextToken(appData.data.reqsByStatusSorted.nextToken);
          setTotalPages(pages);
        }
        // localStorage.setItem('requestsFilterByStatus', st);
        setSpinner(false);
      } catch (err) {
        console.log("error listing Requests by Status", err);
    }
  }


  async function fetchRequest(e) {
    if (e === "") return;
    try {
      document.getElementById("filterStatus").value = "ALL";
      const query = {
        accountName: { wildcard: `*${e.toLowerCase()}*` },
      };
      const reqData = await API.graphql(
        graphqlOperation(searchRequestss, { filter: { and: query } } )
      );
      if (reqData.data.searchRequestss.items.length > 0) {
        const reqs = reqData.data.searchRequestss.items;
        if (reqs && reqs.length > 0) {
          setRequests([...reqs]);
          setRequestsCount(reqData.data.searchRequestss.total);
          handleResetPage();
        }
      }
    } catch (err) {
      console.log("error fetching Requests", err);
    }
  }

  const loadRequest = async (id) => {    navigate(`../request/${id}`, { replace: false });
  };

  const newRequest = async (id) => {
    navigate(`../request/new`, { replace: false });
  };

  const handleNextPage = () => {
    setPreviousToken((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setNextToken(previousTokens.pop());
    setPreviousToken([...previousTokens]);
    setNextNextToken(null);
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleResetPage = () => {
    setNextToken(undefined);
    setPreviousToken([]);
    setNextNextToken(null);
  }

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      addSnack({ variant: 'success', message: 'Link copied to clipboard' });
    } catch (err) {
      err.log("Failed to copy!");  //     addSnack({ variant: 'danger', message: 'Error copying to clipboard' });
    }
  };

  const deleteIt = (id, name) => {
    setRequestId(id);
    setAccountName(name)
    toggleOpen(true);
  }

  const deleteRequest = async () => {
    try {
        await API.graphql(
          graphqlOperation(deleteRequests, { input: { id: requestId }})
        );
        toggleOpen(false);
        setRequests(Requests.filter(req => req.id !== requestId));
    } catch (err) {
      console.log("error deteting Request", err);
      toggleOpen(false);
      addSnack({ variant: 'danger', message: 'Error deleting Request' });
    }
  }

    const buildApp = (app, status) => {
      console.log(app);
      return {
        id: app.id,
        clientId: app.clientId,
        status: status,
        accountName: app.accountName,
        salesforce: app.salesforce,
        offer: app.offer,
        states: JSON.stringify(app.states.replace(/\s/g, "")),
        offersRequested: app.offersRequested,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        paymentAmount: app.paymentAmount,
        calculatorType: app.calculatorType,
        integratorId: app.integratorId,
        redirectUrl: app.redirectUrl,
        submittingUser: {
          firstName: app.submittingUser.firstName,
          lastName: app.submittingUser.lastName,
          email: app.submittingUser.email,
          phone: app.submittingUser.phone,
        },
        sendEmail: app.sendEmail,
        referenceId: app.referenceId,
        contactEmail: app.contactEmail,
        partnerEmail: app.partnerEmail,
        apiKeys: app.apiKeys,
        notes: app.notes
      };
  };

  const setAsCompleted = async (req, index) => {
    try {
      const app = buildApp(req, "COMPLETED");
      await API.graphql(graphqlOperation(updateRequests, { input: app }));
      addSnack({ variant: 'success', message: 'Request updated' });
      req.status = "COMPLETED";
      const reqsCopy = Requests;
      reqsCopy[index] = req;
      setRequests(reqsCopy);
     } catch (err) {
      console.log("error updating Request", err);
      addSnack({ variant: 'danger', message: 'Error updating Request' });
    }
  }

 const showNotes = (req) => {
  setNotesMessage(req.notes);
  setAccountName(req.accountName);
  toggleOpenNotes(true);
 }

 const sendEmailTo = (req) => {
  setAccountName(req.accountName);
  setRequest(req);
  toggleOpenComms(true);
 }

  const getCategory = (category) => {
    if (category === "HOME_IMPROVEMENT") return HOME_IMPROVEMENT
    else if (category === "ROOF") return ROOF;
    else if (category === "SOLAR") return SOLAR;
    else if (category === "BATTERY") return BATTERY;
  }

  const getCorp = (integratorId, bundleClientId) => {
    if (!!integratorId) return  CORP
    else if (!!bundleClientId) return PLUS
    else return BLANK
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "22px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <View>Request List <span style={styles.count}>({requestCount})</span></View>
                  <SearchField
                    ref={inputRef}
                    label="Search"
                    placeholder="Search by Account Name..."
                    size="small"
                    onSubmit={(e) => fetchRequest(e)}
                    onClear={() => fetchRequests()}
                  />
                </Flex>
              </Heading>
              <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "22px",
                }}
              >
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
              <Box sx={{
                  width: 250,
                  marginBottom: 20
                }}>
                  <SelectField
                    id="filterStatus"
                    style={styles.input}
                    descriptiveText="Filter by Status"
                    onChange={(e) => listRequestsByStatus(e.target.value)}
                    size="small"
                  >
                    <option value="ALL">All</option>
                    <option value="NEW">{Status.NEW}</option>
                    <option value="NEWPLUS">{Status.NEWPLUS}</option>
                    <option value="WAITINGONAPIKEYS">{Status.WAITINGONAPIKEYS}</option>
                    <option value="READYFORREQUESTER">{Status.READYFORREQUESTER}</option>
                    <option value="READYFORCREATION">{Status.READYFORCREATION}</option>
                    <option value="INPROGRESS">{Status.INPROGRESS}</option>
                    <option value="UPDATEQUICKLINK">{Status.UPDATEQUICKLINK}</option>
                    <option value="BLOCKED">{Status.BLOCKED}</option>
                    <option value="COMPLETED">{Status.COMPLETED}</option>
                  </SelectField>
              </Box>
              <LumosButton onClick={newRequest} style={styles.newRequestButton}>
                New Request
              </LumosButton>
              </Flex>
              </Heading>
              </Box>
              {Requests.length ? (
                <>
                <Table caption="" highlightOnHover={false} size="small" variation="striped">
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Status</TableCell>
                      <TableCell as="th">Account Name</TableCell>
                      <TableCell as="th">Last Updated</TableCell>
                      <TableCell as="th">Cat</TableCell>
                      <TableCell as="th">Org</TableCell>
                      <TableCell as="th"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Requests.map(({id, status: st, accountName, salesforce, category, updatedAt, integratorId, bundleClientId }, index, requests) => (
                      <TableRow key={id ? id : index}>
                        <TableCell><Badge variant={StatusVariant.find(el => el.type === st).variant}>{`${Status[st]}`}</Badge></TableCell>
                        <TableCell>{accountName}</TableCell>
                        <TableCell>{formatDateTime(updatedAt)}</TableCell>
                        <TableCell><img src={getCategory(category)} alt="HI" style={styles.catLogo} /></TableCell>
                        <TableCell>
                         <img src={getCorp(integratorId, bundleClientId)} alt={integratorId ? integratorId : bundleClientId ? bundleClientId : ""} style={styles.corpLogo} />
                        </TableCell>
                        <TableCell>
                          <View width="4rem">
                            <Menu menuAlign="start" size="small">
                              <MenuItem size="small" onClick={() => loadRequest(id)}><Icon variant="edit" size="sm" sx={{marginRight: '5px'}} />Edit</MenuItem>
                              {st === "COMPLETED" && <MenuItem size="small" onClick={() => sendEmailTo(requests[index])}><Icon variant="envelope" size="sm" sx={{marginRight: '5px'}} />Send Email</MenuItem>}
                              <MenuItem size="small" onClick={() => setAsCompleted(requests[index])}><Icon variant="check" size="sm" sx={{marginRight: '5px'}} />Set as Completed</MenuItem>
                              <MenuItem size="small" onClick={() => showNotes(requests[index])}><Icon variant="notes" size="sm" sx={{marginRight: '5px'}} />See Notes</MenuItem>
                              <MenuItem size="small" onClick={() => {copyToClipBoard(salesforce)}}><Icon variant="clipboard" size="sm" sx={{marginRight: '5px'}} />Copy SFDC to Clipboard</MenuItem>
                              <MenuItem size="small" onClick={() => deleteIt(id, accountName)}><Icon variant="trash" size="sm" sx={{marginRight: '5px'}} />Delete</MenuItem>
                            </Menu>
                          </View>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                              <Box sx={{ marginTop: 20 }}>
                <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem"
                >
                {totalPages > 0 && (
                  <>
                    <LumosButton disabled={!hasPrev} onClick={handleResetPage} style={styles.paginationButton}>
                      <BiChevronsLeft size={'1rem'} /> First Page
                    </LumosButton>
                    <LumosButton disabled={!hasPrev} onClick={handlePreviousPage} style={styles.paginationButton}>
                      <BiChevronLeft size={'1rem'} /> Prev Page
                    </LumosButton>
                    <LumosButton disabled={!hasNext} onClick={handleNextPage} style={styles.paginationButtonRight}>
                      Next Page <BiChevronRight size={'1rem'} />
                    </LumosButton>
                    <SelectField
                      id="pageSize"
                      style={styles.select}
                      descriptiveText="Page Size"
                      onChange={(e) => handlePageSizeChange(e.target.value)}
                      size="small"
                      value={pageSize}
                    >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="35">35</option>
                  </SelectField>
                  </>
                )}
              </Flex>
                </Box>
                </>
              ) : (
                <AlertInline variant="info">No items found.</AlertInline>
              )}
            </Box>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
          {/* Modal to delete request */}
          <Modal isOpen={isOpen} onDismiss={() => toggleOpen(false)} title="Delete Request">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "18px",
                }}
              >
            Are you sure you want to delete {accountName ? accountName : 'it'}?
            </Heading>
            <ModalControls actions={[
                {
                  onClick: () => deleteRequest(),
                  label: 'Delete',
                },
                {
                  onClick: () => toggleOpen(false), label: 'Cancel'
                }
              ]}
            />
          </Modal>
          {/* END Modal to delete request */}
          {/* Modal to display Notes */}
          <Modal isOpen={isOpenNotes} onDismiss={() => toggleOpenNotes(false)} title="Request Notes">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "16px",
                  whiteSpace: "pre-line"
                }}
              >
            {accountName}
            </Heading>
            <Box
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "100px",
                  whiteSpace: "pre-line"
                }}
              >
            {notesMessage}
            </Box>
            <ModalControls actions={[
                {
                  onClick: () => toggleOpenNotes(false), label: 'Close'
                }
              ]}
            />
          </Modal>
          {/* END Modal to display Notes */}
          {/* Modal to Send Communication emails */}
          <Modal variant="large" isOpen={isOpenComms} onDismiss={() => toggleOpenComms(false)} title="Send QuickLink To">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "18px",
                  whiteSpace: "pre-line"
                }}
              >
            {accountName}
            </Heading>
            <Box
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "14px",
                  fontWeight: "100px",
                  whiteSpace: "pre-line"
                }}
              >
            {request && (
            <LumosForm
              initialValues={{
                emails: [request.contactEmail],
                otherEmail: '',
              }}
              validationSchema={object({
                emails: array().of(string()).min(1, 'Select at least one email')
              })}
              onSubmit={(values, { setFieldError}) => {
                if (values.emails.find(e => e === 'other') && values.otherEmail === "") {
                   setFieldError("otherEmail", "Please enter a valid email");
                } else if (values.otherEmail !== "" && !email(values.otherEmail)) {
                  setFieldError("otherEmail", "Please enter a valid email");
                } else {
                  const emailValues = [...values.emails.filter(e => e !== "other"), values.otherEmail]
                  handleSubmitSendQuickLinkEmail(request, emailValues.filter(e => e !== ""));
                }
            }}>
              <ChoiceInput name="emails" allowMultiple label="Send Quicklink to" description="Select email(s) to send QuickLink email to" items={[{
                label: `Requester: ${request.contactEmail}`,
                value: request.contactEmail
              }, {
                label: `Partner: ${request.partnerEmail}`,
                value: request.partnerEmail
              }, {
                label: 'Other',
                value: 'other'
              }]} />
              <TextInput name="otherEmail" placeholder="j.doe@example.com" />
              <LumosButton type="submit">Send Email</LumosButton>
              </LumosForm>
            )}
            </Box>
            <ModalControls actions={[
                {
                  onClick: () => { toggleOpenComms(false) }, label: 'Close'
                }
              ]}
            />
          </Modal>
          {/* END Modal to Send Communication emails */}
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "20px 20px"
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 0,
    padding: 6,
    fontSize: 14,
    // width: "250px"
  },
  select: {
    marginBottom: 0,
    padding: 6,
    fontSize: 12,
    // width: "30px"
  },
  switch: {
    marginBottom: 10,
    // padding: 8,
    fontSize: 16,
  },
  accountName: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  paginationButton: {
    marginTop: "30px",
    width: 130,
    height: 40,
  },
  paginationButtonRight: {
    width: 130,
    height: 40,
    marginTop: "30px",
    marginLeft: "200px"
  },
  menuButton: {
    backgroundColor: "#00838f",
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  newRequestButton: {
    width: 130,
    height: 40,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
  catLogo: {
    width: 25,
    position: "relative",
    top: 5,
    margin: "0 auto",
  },
  corpLogo: {
    width: 25,
    position: "relative",
    top: 5,
    margin: "0 auto",
  },
  count: {
    fontSize: "80%",
    fontWeight: 100,
    position: "relative",
    top: -1
  }
};

export default Requests;
