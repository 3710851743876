import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Box, Spinner, Grid, Text, Image } from 'theme-ui';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useFormikContext } from 'formik';
import { Card,
         Form,
         DataList,
         Button as LumosButton,
         useNotify,
         NumberInput,
         SingleSelect,
         Heading,
         Icon,
         ChoiceInput,
         AlertBanner
        } from '@loanpal/lumos__core';
import { styles } from "./styles";
import {
  TextField,
  Flex,
  FieldGroupIcon,
  Divider
} from "@aws-amplify/ui-react";
import {
  numberToMoney,
  moneyStringToNumber,
  validateMoneyInput,
  dateStr
} from "../../utils/format";

import Loading from "../../utils/loading";
import { ResetBar } from "./reset-button";

import { getLoanById,
         getLoanByTags,
         getOfferByLoanId,
         getLoanStatus,
         getLimitations,
         getCategories,
         getPayment,
         changeOrders,
         deleteTagByLoanId,
         addTagByLoanId,
         requestLoanDocuments,
         getOffersByChannel
} from "../../utils/api";
import { DisplayBundleDetails, DisplayLoan, DisplayConditionalDetails } from "../application";
import { generateShortUUID } from "../../utils/uuidGenerator";

import logoGLP from '../../images/glplus_grey_gradient.svg';

const removeQueryStringParameters = (url) => {
    // Use the URL constructor to parse the URL
    const urlObject = new URL(url);

    // Clear all search parameters
    urlObject.search = '';

    // Return the updated URL
    return urlObject.toString();
}

const resetForm = () => {
    localStorage.removeItem("bundleSolarLoan");
    window.location.href = removeQueryStringParameters(window.location.href);
}

export const SelectAction = ({ send }) => {
    const { values, setFieldValue } = useFormikContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shouldSelectAction = Boolean( queryParams.size === 0 );

    useEffect(() => {
        if (values.selectedAction !== "") {
            setFieldValue("selectedAction", values.selectedAction);
        }
     // eslint-disable-next-line
    }, [values.selectedAction]);

    useEffect(() => {
        if (localStorage.getItem('bundleSolarLoan')) {
            setFieldValue("selectedAction", "statusBundleAction");
            setFieldValue("selectedCategory", "SOLAR");
        }
     // eslint-disable-next-line
    }, [values.selectedAction]);

     if (!shouldSelectAction) return null;

    const actionItems =  [
        {name: "Upgrade Solar Loan to GoodLeap+", value: "bundleSolarLoanAction"},
        {name: "Submit Change Order to finalize GoodLeap+ bundle", value: "changeOrderAction"},
        {name: "Check Status of GoodLeap+ loan", value: "statusBundleAction"}
    ]

  return (
    <Card style={styles.cardBox}>
      <Box
        sx={{
          button: {
            minHeight: 40,
            display: 'grid',
            gridTemplateColumns: '1fr 24px',
            textAlign: 'left'
          },
        }}
      >
        <SingleSelect
          placeholder="None selected"
          name="selectedAction"
          label="What action you want to perform?"
          sxProps={{ mb: 0, maxWidth: 500 }}
          items={actionItems}
          readOnly={values.selectedAction !== ""}
        />
      </Box>
    </Card>
  );
}

export const ChooseBundleLoan = ({ hiClientId, solarClientId, hiChannel, sharedAppStates, setLoading }) => {
    const { values, setValues, setFieldValue } = useFormikContext();
    const [ spinner, setSpinner] = useState(false);
    const [ loanExists, setLoanExists] = useState({exists: true, status: ""});
    const [ loanErrorMessage, setLoanErrorMessage] = useState("");
    const [ stateErrorMessage, setStateErrorMessage ] = useState({variant: "", message: ""});
    const { addSnack } = useNotify();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shouldChooseBundleLoan = Boolean( values.selectedAction !== "" || queryParams.size > 0 );
    const [ bundleIds ] = useState({solarId: queryParams.get('referenceNumber'), hiId: queryParams.get('loanId') });
    const [ prepopLoanId, setPrepopLoanId ] = useState(localStorage.getItem("bundleSolarLoan") ? localStorage.getItem("bundleSolarLoan") : "");
    const whatLoanIsNeeded = values.selectedAction === "changeOrderAction" ? "HI"
        : values.selectedAction === "bundleSolarLoanAction" ? "SOLAR"
        : values.selectedAction === "statusBundleAction" && values.selectedCategory === "SOLAR" ? "SOLAR"
        : values.selectedAction === "statusBundleAction" && values.selectedCategory === "HI" ? "HI"
        : values.selectedAction === "statusBundleAction" && values.selectedCategory === "" ? "" : "SOLAR";

    useEffect(() => {
        if (values.selectedAction === "bundleSolarLoanAction" && hiClientId && hiChannel) {
            getOffers(hiClientId, hiChannel);
        }
     // eslint-disable-next-line
    }, [values.selectedAction, hiClientId, hiChannel]);

    useEffect(() => {
        if (values.solarLoan) {
            setLoanExists({exists: true, status: "ready"});
        }
        // eslint-disable-next-line
    }, [values.solarLoan]);

    useEffect(() => {
        if (bundleIds.solarId && bundleIds.hiId) {
            setLoading(true);
            setFieldValue("loanId", bundleIds.solarId);
            setPrepopLoanId(bundleIds.solarId);
            getLoan(solarClientId, bundleIds.solarId, hiClientId, bundleIds.hiId);
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [bundleIds]);

    if (!shouldChooseBundleLoan) return null;

  const getOffers = async (clientId, channel) => {
    await getOffersByChannel(clientId, channel).then(response => {
        console.log("OFFER", channel, response);
        if (response.data.length > 0) {
            setFieldValue("offers", response.data);
            let offersArrayOptions = [];
            offersArrayOptions = response.data
            .map((element) => [
                ...offersArrayOptions,
                { name: `${element.name} - ${element.productName}`, value: element.id },
            ])
            .flat();
            setFieldValue("offerItems", offersArrayOptions);
            // if (response.data.length === 1) {
                    setFieldValue("selectedOffer", response.data[0].id);
                    setFieldValue("dealerFee", response.data[0].dealerFee);
                    setFieldValue("selectedStates", response.data[0].states);
                // if (!!JSON.parse(sharedAppStates)) {
                //     setFieldValue("selectedStates", sharedAppStates);
                // } else {
                //     setFieldValue("selectedStates", response.data[0].states);
                // }
            // }
        }
    });
}

  const validateBundle = (loan, bundleLoans) => {
    return bundleLoans.find((l) => l.subjectProperty.address.city.toUpperCase() === loan.subjectProperty.address.city.toUpperCase() && l.subjectProperty.address.street.toUpperCase() === loan.subjectProperty.address.street.toUpperCase());
  }

   const validateBundle2 = (loan, bundleLoan) => {
    return bundleLoan.subjectProperty.address.city.toUpperCase() === loan.subjectProperty.address.city.toUpperCase() && bundleLoan.subjectProperty.address.street.toUpperCase() === loan.subjectProperty.address.street.toUpperCase();
  }

  // If Solar Loan is not found, look to see if it's a HI loan and then if tagged with Solar loan load newBundle
  const lookForHiLoan = async (solarClientId, loanId, hiClientId) => {
    try {
        setLoanExists({exists: false, status: "loading"});
        setSpinner(true);
        setLoading(true);
        // get loan
        await getLoanById(hiClientId, loanId).then(async (hiLoan) => {
            console.log("HILOAN", hiLoan);
            if (hiLoan && hiLoan.tags && hiLoan.tags.length > 0) {
                const itIsABundle = findIfItsABundle(hiLoan.tags);
                console.log("ITSABLUNDLE", itIsABundle);
                let solarLoan = null
                if (itIsABundle) {
                    await getLoanByTags(solarClientId, hiLoan.tags).then((response) => {
                        solarLoan = response.loan.data[0]
                    });

                } else  {
                    solarLoan = await getLoanById(solarClientId, hiLoan.tags[0]);
                }
                if (solarLoan) {
                    console.log("SOLARLOAN", solarLoan);
                    const bundleLoan = validateBundle2(solarLoan, hiLoan);
                    const finalBundleLoan = findBundleTags(solarLoan.tags === null ? [] : solarLoan.tags, hiLoan.tags);
                    if (bundleLoan) {
                        setValues({
                            ...values,
                            solarLoan: solarLoan,
                            solarLoanId: solarLoan.id,
                            referenceNumber: solarLoan.referenceNumber,
                            borrowerFirstName: solarLoan.borrower.firstName,
                            borrowerLastName: solarLoan.borrower.lastName,
                            borrowerPhone: solarLoan.borrower.phones.length > 0 ? solarLoan.borrower.phones[0].value : "",
                            borrowerStreetAddress: solarLoan.borrower.address.street,
                            borrowerStateAddress: solarLoan.borrower.address.state,
                            borrowerCityAddress: solarLoan.borrower.address.city,
                            bundleTag: solarLoan.id,
                            solarLoanAmount: solarLoan.amount.value,
                            hiLoan: hiLoan,
                            hiLoanId: hiLoan.id,
                            spinner: false,
                            newBundle: finalBundleLoan ? false : true
                        })
                        setLoanExists({exists: true, status: "ready"});
                        setSpinner(false);
                    } else {
                        addSnack({ variant: 'danger', message: `Not a bundle!` });
                        setLoanExists({exists: false, status: "ready"});
                        setSpinner(false);
                    }
                } else {
                    addSnack({ variant: 'danger', message: `Couldn't find solar loan!` });
                    setLoanExists({exists: false, status: "ready"});
                    setSpinner(false);
                }
            } else {
                addSnack({ variant: 'danger', message: `Couldn't find HI loan!` });
                setSpinner(false);
                setFieldValue("spinner", false);
                setLoanExists({exists: false, status: "ready"});
                return false;
            }
        })
    } catch(err) {
       console.log("error getting bundle loans", err);
        addSnack({ variant: 'danger', message: `Couldn't find HI loan!` });
        setSpinner(false);
        setFieldValue("spinner", false);
        return false;
    } finally {
        setSpinner(false);
        setLoading(false);
    }
  }

  const findBundleTags = (arr1, arr2) => {
    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
        if (arr1[i] === arr2[j]) {
            return arr1[i]; // Found a match, return the equal value
        }
        }
    }
    return null; // No equal values found
  }

  const findIfItsABundle = (arr1) => {
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].includes("GLP-QL")) {
        return true; // Found a match, return the equal value
      }
  }
  return false; // No equal values found
}

  const getLoan = async (solarClientId, loanId, hiClientId, hiLoanId) => {
  try {
    setLoanErrorMessage({variant: "", message: ""});
    if (localStorage.getItem("bundleSolarLoan")) loanId = localStorage.getItem("bundleSolarLoan");
    if (!loanId) return null;
    setLoanExists({exists: false, status: "loading"});
    setSpinner(true);
    setLoading(true);
    // get offer if no offer yet
    // if (values.solarLoan && (!values.selectedOffer || !values.dealerFee)) {
    //     await getOffers(hiClientId, hiChannel);
    // }
    // get loan
    if (whatLoanIsNeeded === "SOLAR") {
        await getLoanById(solarClientId, loanId).then(async (solarLoan) => {
            console.log("SOLAR LOAN", solarLoan);
        if (solarLoan) {
            if (!values.selectedOffer || !values.dealerFee) {
                await getOffers(hiClientId, hiChannel);
            }
            if (values.selectedAction !== "" && values.selectedAction !== "statusBundleAction" && values.selectedStates && !values.selectedStates.find(st => solarLoan.subjectProperty.address.state === st)) {
                setStateErrorMessage({variant: "danger", message: `GoodLeap+ offer is not configured for properties in ${solarLoan.subjectProperty.address.state}`});
                setSpinner(false);
                setLoanExists({exists: true, status: ""});
                return null;
            } else if (values.selectedAction !== "" && !values.selectedStates) {
                setStateErrorMessage({variant: "warning", message: `Something went wrong looking for loan, please try again!`});
                setSpinner(false);
                setLoanExists({exists: true, status: ""});
                return null;
            }
            setValues({
                ...values,
                solarLoan: solarLoan,
                solarLoanId: solarLoan.id,
                referenceNumber: solarLoan.referenceNumber,
                borrowerFirstName: solarLoan.borrower.firstName,
                borrowerLastName: solarLoan.borrower.lastName,
                borrowerPhone: solarLoan.borrower.phones.length > 0 ? solarLoan.borrower.phones[0].value : "",
                borrowerStreetAddress: solarLoan.borrower.address.street,
                borrowerStateAddress: solarLoan.borrower.address.state,
                borrowerCityAddress: solarLoan.borrower.address.city,
                bundleTag: solarLoan.id,
                solarLoanAmount: solarLoan.amount.value,
                hasCoborrower: solarLoan.coBorrower ? 'yes' : 'no',
                selectedState: solarLoan.subjectProperty.address.state
            })
            setLoanExists({exists: true, status: "ready"});
            if (hiLoanId !== "")  {
                // This might be a new bundle, if so need a change order and tags
                await getLoanById(hiClientId, hiLoanId).then(hiLoan => {
                    const itIsABundle = findBundleTags(solarLoan.tags, hiLoan.tags);
                    if (hiLoan && !itIsABundle) {
                        const bundleLoan = validateBundle2(solarLoan, hiLoan);
                        if (bundleLoan) {
                            setFieldValue("hiLoan", hiLoan);
                            setFieldValue("hiLoanId", hiLoan.id);
                            setFieldValue("bundleTag", hiLoan.tags ? hiLoan.tags[0] : "");
                            setFieldValue("newBundle", true);
                            setFieldValue("spinner", false);
                            setSpinner(false);
                        } else {
                            setSpinner(false);
                        }
                    } else if (hiLoan && itIsABundle) {
                        setFieldValue("hiLoan", hiLoan);
                        setFieldValue("hiLoanId", hiLoan.id);
                        setFieldValue("bundleTag", itIsABundle);
                        setFieldValue("spinner", false);
                        setSpinner(false);
                    } else {
                        setSpinner(false);
                    }
                });
            } else if (solarLoan.tags && solarLoan.tags.length > 0 && hiLoanId === "") {
                const hiLoan = await getLoanByTags(hiClientId, solarLoan.tags);
                console.log("BUNDLE", solarLoan.tags, hiLoan);
                if (hiLoan) {
                    const itIsABundle = findBundleTags(solarLoan.tags, hiLoan.loan.data[0].tags);
                    console.log("ITS A BUNDLE", !!itIsABundle);
                    if (hiLoan.loan.data.length > 0 && !!itIsABundle) {
                        const bundleLoan = validateBundle(solarLoan, hiLoan.loan.data);
                        if (bundleLoan) {
                            setFieldValue("hiLoan", bundleLoan);
                            setFieldValue("hiLoanId", bundleLoan.id);
                            setFieldValue("bundleTag", itIsABundle);
                            setFieldValue("spinner", false);
                            setSpinner(false);
                        }
                    } else if (solarLoan.tags.find((t) => t === "goodleapplus")) {
                        setFieldValue("newBundle", true);
                        setSpinner(false);
                    } else {
                        setSpinner(false);
                    }
                } else if (solarLoan.tags.find((t) => t === "goodleapplus")) {
                    setFieldValue("newBundle", true);
                    setSpinner(false);
                }
            }

        } else {
            setLoanExists({exists: false, status: "undefined"});
            setLoanErrorMessage(`${solarClientId} loan doesn't exist`);
            setSpinner(false);
            setFieldValue("spinner", false);
            addSnack({ variant: 'warning', message: `Couldn't find loan, let's look for hi loan!` });
            lookForHiLoan(solarClientId, loanId, hiClientId);
        }
        });
    } else {
        // this might be a unattached bundle needing a change order
        lookForHiLoan(solarClientId, loanId, hiClientId);
    }
  } catch (err) {
    console.log("error getting loans", err);
    addSnack({ variant: 'danger', message: `Error getting loan!` });
    setSpinner(false);
    setFieldValue("spinner", false);
    lookForHiLoan(solarClientId, loanId, hiClientId, hiLoanId);
    return false;
  } finally {
    setSpinner(false);
    setFieldValue("spinner", false);
    setLoading(false);
  }
}

const clearValues = () => {
    setValues({
        ...values,
        loanId: "",
        solarLoanId: "",
        hiLoanId: "",
        solarLoan: undefined,
        hiLoan: undefined,
        selectedLoanAmount: undefined,
        selectedBundleAmount: 0,
        referenceNumber: "",
        borrowerFirstName: "",
        borrowerLastName: "",
        borrowerPhone: "",
        borrowerStreetAddress: "",
        borrowerStateAddress: "",
        borrowerCityAddress: ""
        });
    }

    const categoryItems =  [
        {name: "Solar", value: "SOLAR"},
        {name: "Home Improvement", value: "HI"}
    ]

  return(
        <>
        {queryParams.size === 0 &&
        <Card style={styles.cardBox}>
        {values.selectedAction === "statusBundleAction" && (
        <Box
            sx={{
            button: {
                minHeight: 40,
                display: 'grid',
                gridTemplateColumns: '1fr 24px',
                textAlign: 'left'
            },
            mb: 2
            }}
        >
            <SingleSelect
            placeholder="Select category..."
            name="selectedCategory"
            label="Select the loan category"
            sxProps={{ mb: 0, maxWidth: 500 }}
            items={categoryItems}
            dangerouslyOnChange={(target) => {
                setFieldValue("selectedCategory", target);
            }}
            />
        </Box>
        )}
        <Box
            sx={{
            button: {
                minHeight: 40,
                display: 'grid',
                gridTemplateColumns: '1fr 24px',
                textAlign: 'left'
            },
            }}
            >
          <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
            <TextField
              label={`Enter ${whatLoanIsNeeded} Loan ID`}
              errorMessage={loanErrorMessage}
              defaultValue={prepopLoanId}
              name="loanId"
              size="small"
              readOnly={whatLoanIsNeeded === ""}
              width={{base: "100%", large: "50%"}}
              onChange={(e) => {
                clearValues();
                setLoanExists({exists: false, status: ""});
                setFieldValue("loanId", e.target.value.trim());
              }}
              style={styles.label}
              innerEndComponent={
                  // loan exists
                  values.loanId && (
                  <FieldGroupIcon
                    ariaLabel="Check"
                    variation="link"
                    height="90%"
                  >
                    {loanExists.status === "ready"  && (
                      <i className="gg-check-o-green"></i>
                    )}
                  </FieldGroupIcon>)
              }
            />
            <Box>
              {spinner && (
                <Box sx={{ minHeight: 35, marginTop: "20px" }}>
                  <Spinner style={styles.spinner} size="40" />
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        <Box sx={{ minHeight: 35, marginTop: "15px"}}>
          <LumosButton onClick={() => {setLoanExists({exists: false, status: "loading"}); setStateErrorMessage({variant: "", message: ""}); setTimeout(() => {getLoan(solarClientId, values.loanId, hiClientId, "")}, 3000)}} disabled={loanExists.status === "loading"}>
            {loanExists.status === "loading" ? (
                <div styles={styles.isLoading}>
                    <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                    SEARCHING...
                </div>
                ) : (
                'SEARCH FOR LOAN'
                )}
          </LumosButton>
          {/* <LumosButton onClick={resetForm} sx={{ ml: 2}} variant="secondary">
            RESET FORM
          </LumosButton> */}
          {stateErrorMessage.message &&  (
            <>
            <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
            />
            <AlertBanner variant={stateErrorMessage.variant} sx={{mt: 2}}>
                {stateErrorMessage.message}
            </AlertBanner>
            </>
        )}
        </Box>
    </Card>
    }
    </>
  )
}

const DisplayBundleNextSteps = ({ solarLoan, hiLoan, addSnack, setFieldValue, selectedAction }) => {
    const [loader, setLoader] = useState(false);
    const [ solarOffer, setSolarOffer ] = useState();
    const [ hiOffer, setHiOffer ] = useState();

    useEffect(() => {
        if (solarLoan && hiLoan) {
            getOffer(solarLoan.partnerId, solarLoan.id, "solar");
            getOffer(hiLoan.partnerId, hiLoan.id, "hi");
        }
        // eslint-disable-next-line
    }, [solarLoan, hiLoan]);

    const getLoanDocuments = async () => {
        try {
            setLoader(true);
            await requestLoanDocuments(hiLoan.partnerId, hiLoan.id).then((response) => {
                if (response) {
                    addSnack({ variant: 'success', message: `Loan Documents have been sent by email!` });
                }
            });

        } catch (err) {
            console.log("error getting loan documents", err);
            addSnack({ variant: 'danger', message: `Error requesting loan documents!` });
            return false;
        } finally {
            setLoader(false);
        }
    }


    const getOffer = async (clientId, loanId, type) => {
        try {
        // get offer by loan Id
        const offer = await getOfferByLoanId(clientId, loanId);
        if (offer) {
            if (type === "solar") {
                setSolarOffer(offer[0].offer);
            } else {
                setHiOffer(offer[0].offer);
            }
        }
        } catch (err) {
        console.log("error getting offer", err);
        return false;
        }
    }

    const submitChangeOrder = async () => {
         try {
            setLoader(true);
            const amount = Number(solarLoan.amount.value) + Number(hiLoan.amount.value);
            const uuid = generateShortUUID();
            const payload = JSON.stringify({
                changes: [{
                    type: "AMOUNT",
                    value: {
                        type: "USD",
                        value: amount
                    }
                }]
            });
            await changeOrders(solarLoan.partnerId, solarLoan.id, payload).then((response) => {
                if (response) {
                    // display bundle
                    addSnack({ variant: 'success', message: `Change Order succesfully submitted!` });
                    setFieldValue("bundleTag", "GLP-".concat(uuid));
                    setFieldValue("solarLoanAmount", amount)
                    setFieldValue("newBundle", false);
                }
            });
            if (solarLoan.tags && solarLoan.tags.find((t) => t === "goodleapplus")) {
                await deleteTagByLoanId(solarLoan.partnerId, solarLoan.id, "goodleapplus").then((response) => {
                    if (response) {
                        addSnack({ variant: 'success', message: `Tag for Solar removed!` });
                        console.log(response, "Solar Tag deleted");
                    }
                });
            }
            await addTagByLoanId(solarLoan.partnerId, solarLoan.id, "GLP-QL-".concat(uuid)).then((response) => {
                if (response) {
                    addSnack({ variant: 'success', message: `Tag for Solar added!` });
                    console.log(response, "Solar Tag added to loan");
                }
            });
            if (hiLoan.tags && hiLoan.tags[0]) {
                await deleteTagByLoanId(hiLoan.partnerId, hiLoan.id, hiLoan.tags[0]).then((response) => {
                    if (response) {
                        addSnack({ variant: 'success', message: `Tag for Hi removed!` });
                        console.log(response, "Hi Tag deleted");
                    }
                });
            }
            await addTagByLoanId(hiLoan.partnerId, hiLoan.id, "GLP-QL-".concat(uuid)).then((response) => {
                if (response) {
                    addSnack({ variant: 'success', message: `Tag for Hi added!` });
                    console.log(response, "Hi Tag added");
                }
            });
        } catch (err) {
            console.log("error submitting change order", err);
            addSnack({ variant: 'danger', message: `Error submitting change order!` });
            return false;
        } finally {
            setLoader(false);
            getLoanDocuments();
        }

    }

    return (
    <Card style={styles.glpcard}>
        <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '120px', mb: 1}} />
        {selectedAction !== "" &&
        <Grid columns={[2, null, 2]}>
        <Box
            sx={{
            borderRight: '1px solid',
            borderColor: ['transparent', 'transparent', 'secondary.l2'],
            mb: 2,
            ml: 1
            }}
        >
            <Text style={styles.solarPlusText}>Solar Plus Itemized Home Improvement</Text>
            {!solarOffer ? (
                <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
                <Box sx={{mb: 2}}>
                    <Skeleton width={125} height={24} />
                </Box>
                </SkeletonTheme>
            ) : (
                <Box sx={{mb: 2}}>
                    <Text style={styles.offerText}>{solarOffer ? solarOffer.name : ""}</Text>
                </Box>
            )}
            <Box sx={{my: 2}}>
                <Text>Loan ID: {solarLoan.id}</Text>
            </Box>
            <Box>
                <Text style={styles.amountText}>New Loan Amount</Text>
            </Box>
            <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                {numberToMoney(Number(solarLoan.amount.value) + Number(hiLoan.amount.value))}
            </Text>
            <Box style={styles.buttonBox}>
                <LumosButton onClick={() => submitChangeOrder()} sx={{mb: 2}}>
                    {loader ? (
                        <div styles={styles.isLoading}>
                            <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                            SUBMITTING...
                        </div>
                    ) : (
                        'Submit Change Order'
                    )}
                </LumosButton>
            </Box>
            </Box>
            {/* <Divider
                orientation="horizontal"
                style={styles.dividerPlus}
                size="small"
                label="+"
            /> */}
            <Box>
            <Box sx={{mt: 3}}>
                <Text style={styles.hiPlusText}>Home Improvement Interim Loan</Text>
            </Box>
            {!hiOffer ? (
                <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
                <Box sx={{mb: 2}}>
                    <Skeleton width={125} height={24} />
                </Box>
                </SkeletonTheme>
            ) : (
                <Box sx={{mb: 2}}>
                    <Text style={styles.offerText}>{hiOffer ? hiOffer.name : ""}</Text>
                </Box>
            )}
            <Box sx={{my: 2}}>
                <Text>Loan ID: {hiLoan.id}</Text>
            </Box>
            <Box>
                <Text style={styles.amountText}>Loan Amount</Text>
            </Box>
            <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                {numberToMoney(hiLoan.amount.value)}
            </Text>
        </Box>
        </Grid>
        }
        {selectedAction === "" &&
        <>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'neutral.l3'],
                mb: 3,
                }}
            >
            <Heading variant="h4" sx={{ fontWeight: '600', letterSpacing: '0', lineHeight: '28px' }}>
                Next steps
            </Heading>
                {/* Steps Text */}
                <Flex
                direction="row"
                justifyContent="start"
                alignItems="stretch"
                alignContent="flex-end"
                wrap="nowrap"
                gap="1rem"
                >
                    <Icon variant="check-circle" color="primary.d1" />
                    <Text
                    as="div"
                    sx={{
                        fontWeight: '500', fontSize: "1rem"
                    }}
                    >
                    Request and sign loan documents for the secondary Home Improvement loan
                    </Text>
                </Flex>
                <Flex
                direction="row"
                justifyContent="start"
                alignItems="stretch"
                alignContent="flex-end"
                wrap="nowrap"
                gap="1rem"
                >
                    <Icon variant="check-circle" color="primary.d1" />
                    <Text
                    as="div"
                    sx={{
                        fontWeight: '500', fontSize: "1rem"
                    }}
                    >
                        Update your existing Solar loan amount to include the secondary Home Improvement loan.
                    </Text>
                </Flex>
            {/* <Text variant="h3" as="h3">
                {numberToMoney(Number(solarLoan.amount.value) + Number(hiLoan.amount.value))}
            </Text> */}
        </Box>
        <Box style={styles.buttonBox}>
            <Flex
                direction="row"
                justifyContent="start"
                alignItems="stretch"
                alignContent="flex-end"
                wrap="nowrap"
                gap="1rem"
                >
                <LumosButton onClick={() => submitChangeOrder()}>
                    {loader ? (
                        <div styles={styles.isLoading}>
                            <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                            SUBMITTING...
                        </div>
                    ) : (
                        'Update Solar Loan and Get Loan Documents'
                    )}
                </LumosButton>
                {/* <LumosButton onClick={() => getLoanDocuments()}>
                    {loader ? (
                        <div styles={styles.isLoading}>
                            <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                            REQUESTING...
                        </div>
                    ) : (
                        'Get Loan Documents'
                    )}
                </LumosButton> */}
            </Flex>
        </Box>
        <Box sx={{ mt: 3 }}>
            <Text sx={{ fontSize: "0.695rem" }}>If you have already signed loan documents for the Solar loan, you will need to re-sign loan documents plus sign documents for the secondary Home Improvement Loan. Remember that you will not have to make any payments for the secondary loan as long as the Solar loan stays active.</Text>
        </Box>
        </>
        }
    </Card>
    )
}

const DisplayAskForHiLoanId  = ({ solarLoan, addSnack, setFieldValue }) => {
    const [loader, setLoader] = useState(false);

    const removeBundleTag = async () => {
        try {
            setLoader(true);
            if (solarLoan.tags.find((t) => t === "goodleapplus")) {
                await deleteTagByLoanId(solarLoan.partnerId, solarLoan.id, "goodleapplus").then((response) => {
                    if (response) {
                        addSnack({ variant: 'success', message: `Tag for Solar removed!` });
                        console.log(response, "Solar Tag deleted");
                    }
                });
            }
        } catch (err) {
            console.log("error removing goodleapplus tag", err);
            addSnack({ variant: 'danger', message: `Error removing tag!` });
            return false;
        } finally {
            setFieldValue("newBundle", false);
            setLoader(false);
        }
    }

    return (
    <Card style={styles.glpcard}>
        <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '120px', mb: 2}} />
        <Box sx={{ mt: 1, mb: 1 }}>
            <Text>
                <span style={styles.bold}>Bundle Status: </span> Loan <span style={styles.bold}>{solarLoan.id}</span> is part of a bundle but we need the home improvement loan to proceed, please search for the home improvement loan above to continue.
            </Text>
        </Box>
        <Box sx={{ mt: 1, mb: 1 }}>
            <Text>
                <span style={styles.bold}>Remove Bundle: </span> If the home improvment loan was declined or cancelled or you don't know of any home improvement loan associated with this Solar loan, lets remove the GL+ tag.
            </Text>
        </Box>
        <Box style={styles.buttonBox}>
            <LumosButton onClick={() => removeBundleTag()} sx={{mb: 2}}>
                {loader ? (
                    <div styles={styles.isLoading}>
                        <Spinner size={15} sx={{ mr: 2, mb: -1 }} />
                        SUBMITTING...
                    </div>
                ) : (
                    'Remove GL+ Tag'
                )}
            </LumosButton>
        </Box>
    </Card>
    )
}

const getCategory = (category) => {
    if (category === "Hi") return "Home Improvement";
    else if (category === "Roof") return "Roofing / Windows / Doors / Siding / Geothermal";
    else if (category === "Solar") return "Solar";
    else if (category === "Battery") return "Stand-Alone Battery";
  }

export const DisplayBundleOffer = ({ send, hiClientId, solarClientId, handleSubmitButtonState }) => {
    const { values, setFieldValue, setFieldError, setErrors } = useFormikContext();
    const { solarLoan, hiLoan, newBundle, spinner, bundleTag, solarLoanAmount, dealerFee } = values;
    const [readyToContinue, setReadyToContinue] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shouldDisplayLoanDetails = Boolean(
        values.solarLoan !== undefined
    );

    const [solarLoanStatus, setSolarLoanStatus] = useState(null);
    const [hiLoanStatus, setHiLoanStatus] = useState();
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [bundlePaymentAmount, setBundlePaymentAmount] = useState({amount: 0, payment: false});
    const [solarOffer, setSolarOffer ] = useState(null);
    const [limitations, setLimitations] = useState();
    const [minAmount, setMinAmount] = useState();
    const [maxAmount, setMaxAmount] = useState();
    const [bundleMinAmount, setBundleMinAmount] = useState();
    const [bundleMaxAmount, setBundleMaxAmount] = useState();
    const [payment, setPayment] = useState();
    const [timer, setTimer] = useState(null);
    const { addSnack } = useNotify();

    useEffect(() => {
        if (solarLoan) {
            getOfferByLoan(solarClientId, solarLoan.id);
            getStatus(solarClientId, solarLoan.id, "solar");
            getCatLimits(hiClientId, "Roof", undefined);
            getCatLimits(solarClientId, "Solar", solarLoan.maxAmount.value);
            setBundlePaymentAmount({amount: solarLoan.amount.value, payment: true});
        }
        // eslint-disable-next-line
    }, [solarLoan]);

     useEffect(() => {
        if (hiLoan) {
            getStatus(hiClientId, hiLoan.id, "hi");
        }
        // eslint-disable-next-line
    }, [hiLoan]);

    useEffect(() => {
      if (bundlePaymentAmount.payment) {
        getPaymentAmount(solarClientId, solarLoan.offerId, bundlePaymentAmount.amount, solarLoan.enrollments);
      }
    // eslint-disable-next-line
    }, [bundlePaymentAmount])

    if (!shouldDisplayLoanDetails) return null;

    const getOfferByLoan = async (clientId, loanId) => {
        try {
        // get offer by loan Id
        const offer = await getOfferByLoanId(clientId, loanId);
            if (offer) {
                setSolarOffer(offer[0].offer);
            }
        } catch (err) {
            console.log("error getting offer by loan id", err);
        return false;
        }
    }

    const getStatus = async (clientId, loanId, type) => {
    try {
      // get offer by loan Id
      const status = await getLoanStatus(clientId, loanId);
      if (status) {
        if (type === "solar") {
            setSolarLoanStatus(status);
        } else {
            setHiLoanStatus(status);
        }
      }
    } catch (err) {
      console.log("error getting loan status", err);
      return false;
    }
    }

  const getLimits = async (clientId, category) => {
    try {
      // get limitations
      const limitations = await getLimitations(clientId);
      if (limitations) {
        const l = limitations.data.filter((element) => element.categoryId === category)
        setLimitations(l);
      }
    } catch (err) {
      console.log("error getting limitations", err);
      return false;
    }
  }

 const getCatLimits = async (clientId, category, maxAmount) => {
    try {
      // get categories
      const cats = await getCategories(clientId);
      if (cats) {
        const cat = cats.find(el => el.name === getCategory(category).toUpperCase());
        getLimits(clientId, cat.id);
        console.log("LIMITS", cat);
        if (category === "Solar") {
            setBundleMinAmount(cat.minLoanAmount);
            setBundleMaxAmount(maxAmount && maxAmount !== cat.maxLoanAmount ? maxAmount : cat.maxLoanAmount);
        } else {
            setMinAmount(cat.minLoanAmount);
            setMaxAmount(cat.maxLoanAmount);
            setFieldValue("selectedCategory", cat.id);
        }
      }
    } catch (err) {
      console.log("error getting categories", err);
      return false;
    }
  }

    const AmountInput = () => {
        const hiMax =  numberToMoney((Math.floor((Number(solarLoan.maxAmount.value) - Number(solarLoan.amount.value)) * 100) /100) * ((100 - dealerFee)/100));
        // const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
        return(
        <>
         <Box>
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
                alignContent="space-around"
                wrap="wrap"
                gap="1rem"
            >
                <Box>Home Improvement Interim Bid Amount can't be greater than <span style={{fontWeight: 600}}>{hiMax}</span>, this is the max home impovement itemized amount allowed based on the Solar Max Loan Amount of <span style={{fontWeight: 600}}>{numberToMoney(solarLoan.maxAmount.value)}</span>.</Box>
                <NumberInput
                key="loanAmount"
                label="Enter Home Improvement Interim Bid Amount"
                name="loanAmount"
                autoComplete="off"
                autoFocus="autoFocus"
                width={{base: "100%", large: "50%"}}
                style={styles.input}
                sx={{ width: "120%" }}
                //   onBlurCapture={({ target }) => {
                //     const moneyValue = moneyStringToNumber(target.value);
                //     setBundlePaymentAmount({ amount: moneyStringToNumber(target.value), payment: true });
                //     if (Number.isNaN(moneyValue)) return;
                //     const amt = target.value;
                //     const bidAmt = Number(moneyStringToNumber(amt));
                //     setFieldValue('loanAmount', numberToMoney(bidAmt));
                //   }}
                onChangeCapture={({ target }) => {
                    setLoadingPayment(true);
                    setReadyToContinue(false);
                    const amt = target.value;
                    const bidAmt = Number(moneyStringToNumber(amt));
                    const hiAmt = bidAmt / ((100 - dealerFee)/100);
                    const bundleAmt = Number(hiAmt / ((100 - solarOffer.dealerFee)/100)) + Number(solarLoan.amount.value);
                    console.log("SolarDealerFee", ((100 - solarOffer.dealerFee)/100));
                    setBundlePaymentAmount({ amount: bundleAmt, payment: false });
                    setFieldValue('loanAmount', numberToMoney(hiAmt));
                    clearTimeout(timer);
                    const newTimer = setTimeout(() => {
                        setErrors({});
                        const bidAmt = Number(moneyStringToNumber(amt));
                        const hiAmt = bidAmt / ((100 - dealerFee)/100);
                        const bundleAmt = Number(hiAmt / ((100 - solarOffer.dealerFee)/100)) + Number(solarLoan.amount.value);
                        console.log("BUNDLE AMT", hiAmt, solarOffer.dealerFee, (100 - solarOffer.dealerFee)/100, solarLoan.amount.value);
                        console.log("VALUES", values);
                        console.log("LIMITATIONS", limitations, solarLoan.maxAmount.value, solarLoan.amount.value, dealerFee, bidAmt, hiAmt, bundleAmt);
                        console.log("Max HI Amt", numberToMoney((Math.floor((Number(solarLoan.maxAmount.value) - Number(solarLoan.amount.value)) * 100) /100) * ((100 - dealerFee)/100)));
                        if (limitations.find(el => el.state === values.borrowerStateAddress)) {
                            const limits = limitations.find(el => el.state === values.borrowerStateAddress);
                            if (moneyStringToNumber(amt) < limits.minLoanAmount) {
                                setFieldError("loanAmount", `Loan amount needs to be greater than ${numberToMoney(limits.minLoanAmount)}`);
                            } else if (moneyStringToNumber(amt) > limits.maxLoanAmount) {
                                setFieldError("loanAmount", `Loan amount needs to be less than ${numberToMoney(limits.maxLoanAmount)}`);
                            } else if (bundleAmt > (solarLoan.maxAmount.value)) {
                                setFieldError("loanAmount", `Total Solar Plus Itemized amount needs to be less than ${numberToMoney(solarLoan.maxAmount.value)}`);
                            } else {
                                console.log("BUNDLE AMT 1", bundleAmt)
                                setBundlePaymentAmount({ amount: bundleAmt, payment: true });
                                setFieldValue("selectedLoanAmount", numberToMoney(hiAmt));
                                setFieldValue("selectedBundleAmount", bundleAmt);
                                setReadyToContinue(true);
                            }
                        } else {
                            if (moneyStringToNumber(amt) < minAmount) {
                                setFieldError("loanAmount", `Loan amount needs to be greater than ${numberToMoney(minAmount)}`);
                            } else if (moneyStringToNumber(amt) > maxAmount) {
                                setFieldError("loanAmount", `Loan amount needs to be less than ${numberToMoney(maxAmount)}`);
                            } else if (bundleAmt > (solarLoan.maxAmount.value)) {
                                setFieldError("loanAmount", `Total Solar Plus Itemized amount of ${numberToMoney(bundleAmt)} needs to be less than ${numberToMoney(solarLoan.maxAmount.value)}`);
                            } else {
                                console.log("BUNDLE AMT 2", bundleAmt)
                                setBundlePaymentAmount({ amount: bundleAmt, payment: true });
                                setFieldValue("selectedLoanAmount", numberToMoney(hiAmt));
                                setFieldValue("selectedBundleAmount", bundleAmt);
                                setReadyToContinue(true);
                            }
                    }
                    }, 2000)
                    setTimer(newTimer)
                }}
                />
            </Flex>
        </Box>
      </>
    );
   }

   const estimatedPayment = payment ? payment[0].calculations.filter(p => p.type !== "ADJUSTED_W_PAYDOWN").map((pay) => ({
        label: pay.paymentDescription,
        text: <>
            {loadingPayment && pay.type !== "ADJUSTED" ? (
                <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
                <Text variant="h3" as="h2">
                    $ <Skeleton width={125} height={24} />
                </Text>
                </SkeletonTheme>
            ) : pay.type === "INITIAL" ? (
                <Text variant="h1" as="h1" sx={{p: 0}}>
                {numberToMoney(pay.roundedAmount.value)}
                <span style={styles.period} color="neutral.base">
                &nbsp;{pay.interval === "MONTHLY" ? "/ month" : ""}
                </span>
                </Text>
            ) : (
                <Text variant="h5" as="h5" sx={{p: 0}}>
                {numberToMoney(pay.roundedAmount.value)}
                <span style={styles.period} color="neutral.base">
                &nbsp;{pay.interval === "MONTHLY" ? "/ month" : ""}
                </span>
                </Text>
            )}
            </>,
    })) : undefined;

    const getPaymentAmount = async (clientId, offerId, loanAmount, enrollments) => {
        setLoadingPayment(true);
        if (offerId === "" || loanAmount === "" || loanAmount <= bundleMinAmount || loanAmount > bundleMaxAmount || !validateMoneyInput(loanAmount)) {
        //TODO Throw errors if loanAmount is pass max or under min
        console.log("PAYMENT ERROR", offerId, loanAmount, loanAmount <= minAmount, loanAmount > maxAmount, !validateMoneyInput(loanAmount));
        setFieldError("loanAmount", `Need a bid amount to proceed, if payment doesn't load, reset form above!`);
        return;
        }
        try {
            // get payment
            await getPayment(clientId, offerId, loanAmount, enrollments).then(payment => {
                console.log("PAYMENT", clientId, offerId, loanAmount, payment);
                if (payment) {
                    setPayment(payment);
                    setLoadingPayment(false);
                } else {
                    console.log("NO PAYMENT, throw ERROR");
                }
            });
        } catch (err) {
            console.log("error getting payments");
            setLoadingPayment(false);
            return false;
        }
    };

    return (
    <>
    {hiLoan && solarLoan && !newBundle && !spinner && (
        <>
        <DisplayBundleDetails hiLoan={hiLoan} solarLoan={solarLoan} bundleTag={bundleTag} getStatus={getStatus}  addSnack={addSnack} consumerDisplay={queryParams.size > 0} nextSteps={false} />
        <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        </>
    )}
    {hiLoan && hiLoan.status === 'APPROVED' && newBundle && !spinner && (
        <DisplayBundleNextSteps solarLoan={solarLoan} hiLoan={hiLoan} addSnack={addSnack} setFieldValue={setFieldValue} selectedAction={values.selectedAction} />
    )}
    {hiLoan && hiLoan.status === 'CONDITIONAL' && newBundle && !spinner && (
        <>
        <DisplayConditionalDetails hiLoan={hiLoan} addSnack={addSnack} getStatus={getStatus} consumerDisplay={false} nextSteps={true} />
        <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        <DisplayLoan loan={hiLoan} loanStatus={hiLoanStatus} loanAmount={hiLoan.amount.value} consumerDisplay={queryParams.size > 0} conditional={true} hasCoborrower={!! hiLoan.coBorrower}/>
        </>
    )}
    {!hiLoan && newBundle && !spinner && (
        <DisplayAskForHiLoanId solarLoan={solarLoan} addSnack={addSnack} setFieldValue={setFieldValue} />
    )}


    {solarLoanStatus && !solarLoanStatus.installationCompleteAt && solarLoan.status === "APPROVED" && !hiLoan && !newBundle && !spinner &&  (
    <>
    <Divider
        orientation="horizontal"
        style={styles.divider}
        size="small"
    />
    <Card style={styles.glpcard} sx={{mt: 2, mb: 2}}>
        <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '120px', mb: 2}} />
         <Box
            sx={{
            button: {
                minHeight: 20,
                display: 'grid',
                gridTemplateColumns: '1fr 24px',
                textAlign: 'left'
            },
            }}
            >
                <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    alignContent="space-around"
                    wrap="nowrap"
                    gap="1rem"
                >
                    <AmountInput />
                </Flex>
                <Box sx={{ mt: 0, mb: 2 }}>
                <ChoiceInput
                    name="hasCoborrower"
                    label="Will you complete this form"
                    items={[
                    { label: 'By yourself', value: 'no' },
                    { label: 'With a co-borrower', value: 'yes' },
                    ]}
                    onClick={(target) => setFieldValue('hasCoborrower', target.value)}
                />
                </Box>
                 <Flex
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                    <LumosButton type="submit" onClick={() => handleSubmitButtonState("selectLoan")}  disabled={!readyToContinue}>
                        Save and Continue
                    </LumosButton>
                    <LumosButton type="submit" onClick={() => handleSubmitButtonState("sendEmail")}  disabled={!readyToContinue}>
                        Email Customer Form
                    </LumosButton>
                </Flex>
        </Box>
    </Card>
    <Card style={styles.glpcard}>
        <Grid columns={[1, 1, '2fr 2fr']}>
            <Box
                sx={{
                borderRight: '1px solid',
                borderColor: ['transparent', 'transparent', 'secondary.l2'],
                mb: 3,
                }}
            >
                <Text style={styles.solarPlusText}>Solar Plus Itemized Home Improvement</Text>
                {/* <Box sx={{mb: 2}}>
                    <Text style={styles.offerText}>{solarOffer ? solarOffer.name : ""}</Text>
                </Box> */}
                <Box>
                    <Text style={styles.amountText}>Loan Amount</Text>
                </Box>
                {loadingPayment ? (
                    <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
                    <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                        $ <Skeleton width={125} height={24} />
                    </Text>
                    </SkeletonTheme>
                ) : (
                    <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                    {numberToMoney(bundlePaymentAmount.amount)}
                    </Text>
                )}
                <Divider
                    orientation="horizontal"
                    style={styles.dividerPlus}
                    size="small"
                    label="+"
                />
                <Box sx={{mt: 3}}>
                    <Text style={styles.hiPlusText}>Home Improvement Interim Loan</Text>
                </Box>
                {/* <Box sx={{mb: 2}}>
                    <Text style={styles.offerText}>{hiOffer ? hiOffer.name : ""}</Text>
                </Box> */}
                <Box>
                    <Text style={styles.amountText}>Loan Amount</Text>
                </Box>
                {loadingPayment ? (
                    <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
                    <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                        $ <Skeleton width={125} height={24} />
                    </Text>
                    </SkeletonTheme>
                ) : (
                    <Text variant="h3" as="h3" sx={{m: 0, p:0, mb: 3}}>
                        {numberToMoney(values.selectedLoanAmount)}
                    </Text>
                )}
                 <Flex
                    direction="column"
                    justifyContent="start"
                    alignItems="baseline"
                    alignContent="flex-end"
                    wrap="nowrap"
                    gap=".1rem"
                    >
                    <Box>
                        <Icon variant="check" color="info.base" size="sm" />
                        <Text style={styles.hiPlusText} as="span" sx={{ fontWeight: '500', fontSize: "0.895rem", ml: "5px" }}>
                            No payments due while your solar project is in process
                        </Text>
                    </Box>
                    <Box>
                        <Icon variant="check" color="info.base" size="sm" />
                        <Text style={styles.hiPlusText} as="span"sx={{ fontWeight: '500', fontSize: "0.895rem", ml: "5px" }}>
                            Matches itemized amount in your solar project
                        </Text>
                    </Box>
                    <Box>
                        <Icon variant="check" color="info.base" size="sm" />
                        <Text style={styles.hiPlusText} as="span"sx={{ fontWeight: '500', fontSize: "0.895rem", ml: "5px" }}>
                            Utilized to provide payment to home improvement installer
                        </Text>
                    </Box>
                    <Box>
                        <Icon variant="check" color="info.base" size="sm" />
                        <Text style={styles.hiPlusText} as="span"sx={{ fontWeight: '500', fontSize: "0.895rem", ml: "5px" }}>
                            Automatically terminates upon solar completion
                        </Text>
                    </Box>
                </Flex>
            </Box>
            {estimatedPayment && (
                <Box sx={{ m: 2 }}>
                    <Flex>
                        <Image src={logoGLP} alt="GoodLeap+" sx={{ width: '110px', mb: 1, pb: 1}} />
                        <Text variant="h5" as="h5" sx={{ mb: 2, p: 0 }}>Payment</Text>
                    </Flex>
                    <DataList columns={1} items={estimatedPayment} />
                </Box>
            )}
        </Grid>
        <Divider
            orientation="horizontal"
            style={styles.divider20Grey}
            size="small"
        />
        <Box sx={{ textAlign: "center"}}>
            <Text sx={{ fontSize: "0.695rem" }}>You acknowledge you're applying for a second GoodLeap loan matching the total amount of your home improvement attached to your solar project. The second loan is utilized to ensure home improvement provider payment upon completion of that project. The secondary loan is intended to be interim and will terminate upon final completion of your solar project. In an event the home improvement project has been completed and your solar project cancels, the second loan will convert from interim to permanent and you're responsible for the terms & conditions associated.</Text>
        </Box>
    </Card>
    <Divider
        orientation="horizontal"
        style={styles.divider}
        size="small"
    />
    </>
    )}
    {solarLoanStatus && solarLoanStatus.installationCompleteAt && solarLoan.status === "APPROVED" && !hiLoan && !newBundle && !spinner &&  (
        <>
         <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        <AlertBanner variant="danger" sx={{mt: 2}}>
            Solar loan <span style={{fontWeight: 600}}>{solarLoan.id}</span> has been installed  (<span style={{fontWeight: 600}}>{dateStr(solarLoanStatus.installationCompleteAt)}</span>), we can't upgrade it to GoodLeap+ at this stage!
        </AlertBanner>
        </>
    )}
    {solarLoanStatus && solarLoan.status === "DECLINED" && !hiLoan && !newBundle && !spinner &&  (
        <>
         <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        <AlertBanner variant="danger" sx={{mt: 2}}>
            Solar loan <span style={{fontWeight: 600}}>{solarLoan.id}</span> was declined!
        </AlertBanner>
        </>
    )}
    {solarLoan && hiLoan && !newBundle && !spinner && (
        <DisplayLoan loan={solarLoan} loanStatus={solarLoanStatus} loanAmount={solarLoanAmount} consumerDisplay={queryParams.size > 0} hasCoborrower={!! solarLoan.coBorrower} />
    )}
    {hiLoan && !newBundle && !spinner && (
        <>
        <Divider
            orientation="horizontal"
            style={styles.divider}
            size="small"
        />
        <DisplayLoan loan={hiLoan} loanStatus={hiLoanStatus} loanAmount={hiLoan.amount.value} consumerDisplay={queryParams.size > 0} hasCoborrower={!! hiLoan.coBorrower} />
        </>
    )}
    </>
  );
}

const Main = ({ state, send }) => {
  const { sharedApp } = state.context;
  const { addSnack } = useNotify();
  const [loading, setLoading] = useState(true);
  const [submitButtonState, setSubmitButtonState] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetButton = Boolean( queryParams.size === 0);

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit)
  }

  return (
    <>
    {loading && <Loading />}
    <Form
      initialValues={{
        loanId: "",
        solarLoanId: "",
        solarLoan: undefined,
        hiLoanId: "",
        hiLoan: undefined,
        bundleTag: "",
        borrowerFirstName: "",
        borrowerLastName: "",
        loanAmount: "",
        solarLoanAmount: 0,
        selectedLoanAmount: undefined,
        selectedBundleAmount: 0,
        borrowerStreetAddress: "",
        borrowerCityAddress: "",
        borrowerStateAddress: "",
        borrowerPhone: "",
        tags: [],
        referenceNumber: "",
        newBundle: false,
        spinner: true,
        selectedAction: "",
        selectedCategory: "",
        selectedOffer: undefined,
        dealerFee: undefined,
        selectedStates: [],
        selectedState: undefined,
        offers: undefined,
        offerItems: undefined,
        hasCoborrower: undefined
      }}
      onSubmit={({...values}) => {
        const { loanId, solarLoanId, hiLoanId, bundleTag, solarLoan, newBundle, spinner, borrowerFirstName, borrowerLastName, loanAmount, solarLoanAmount, selectedLoanAmount, selectedBundleAmount, borrowerStreetAddress, borrowerCityAddress, borrowerStateAddress, borrowerPhone, referenceNumber, selectedAction, selectedCategory, selectedOffer, dealerFee, selectedStates, selectedState, hasCoborrower } = values;
        const input = { loanId, solarLoanId, hiLoanId, bundleTag, solarLoan, newBundle, spinner, borrowerFirstName, borrowerLastName, loanAmount, solarLoanAmount, selectedLoanAmount, selectedBundleAmount, borrowerStreetAddress, borrowerCityAddress, borrowerStateAddress, borrowerPhone, referenceNumber, selectedAction, selectedCategory, selectedOffer, dealerFee, selectedStates, selectedState, hasCoborrower };
        if (solarLoanId === '') {
          addSnack({ variant: 'danger', message: `Not ready to proceed, please continue!` });
          return null;
        }
        if (submitButtonState === 'selectLoan') {
            send({type: 'SELECTED_LOAN', input});
        } else if (submitButtonState === 'sendEmail') {
            send({type: 'SHARE_APPLICATION', input});
        }

      }}
      showSummary={false}
    >
    {resetButton && <ResetBar onClick={() => resetForm()}>Reset Form</ResetBar>}
    <SelectAction send={send} />
    <ChooseBundleLoan hiClientId={sharedApp.clientId} solarClientId={sharedApp.bundleClientId} hiChannel={sharedApp.channel} sharedAppStates={sharedApp.states}  setLoading={setLoading} />
    <DisplayBundleOffer send={send} hiClientId={sharedApp.clientId} solarClientId={sharedApp.bundleClientId} handleSubmitButtonState={handleSubmitButtonState} />
    </Form>
    </>
  )
};

export default Main;
