import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { updateRequests, createRequests } from "../../graphql/mutations";
import { getRequests, appsByAccountName, appsByClientId } from "../../graphql/queries";
import { useUpdateEffect, useRendersCount } from 'react-use';
import {
  Heading,
  Button as LumosButton,
  useNotify,
  Card as LumosCard,
  Tag,
  Collapsible,
  Icon
 } from "@loanpal/lumos__core";
import { Box, Card } from "theme-ui";
import Loading from "../../utils/loading";
import Offers from "./offers";
import States from "./states";
import Users from "./users";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import { Categories } from "../../utils/categories";
import { Status } from "../../utils/status";
// import EditUserDrawer from './edit-user';
import {
  email
} from "../../utils/formValidation";
import {
  phoneDigits,
  dateTimeStr,
  moneyStringToNumber
} from "../../utils/format";
import { generatePassword } from "../../utils/passwordGenerator";

import UpdatePreview from "./update-preview";

import {
  // withAuthenticator,
  Authenticator,
  TextField,
  SwitchField,
  SearchField,
  View,
  Flex,
  Alert,
  SelectField,
  PasswordField,
  Divider,
  FieldGroupIcon,
  Text,
  Card as ReactCard,
  TextAreaField
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const initialState = {
  id: "",
  clientId: "",
  status: "",
  accountName: "",
  salesforce: "",
  category: "",
  offer: "",
  states: "",
  offersRequested: "",
  loanAmount: 0.0,
  paymentAmount: 0.0,
  calculatorType: "LA",
  integratorId: "",
  redirectUrl: "",
  sendEmail: true,
  referenceId: "quickLink",
  channel: "GoodLink",
  formUrl: "",
  submittingUserFname: "",
  submittingUserLname: "",
  submittingUserEmail: "",
  submittingUserPhone: "",
  submittingUserRole: "",
  submittingUserPassword: "",
  contactEmail: "",
  partnerEmail: "",
  username: "",
  password: "",
  apiKeys: false,
  updateKeys: false,
  notes: "",
  bundleClientId: ""
};

const validRoles = [
    "Sales Rep Advanced Installer",
    "Service Sales Rep Advanced",
    "Service Sales Rep Basic",
    "Service Sales Rep Viewer",
    "Service Manager",
    "Service Admin",
    "Install Lead"
];

const Request = () => {
  const [formState, setFormState] = useState(initialState);
  const [spinner, setSpinner] = useState(false);
  // Change environment dev/prod accordingly
  const [env] = useState("prod");
  const [offers, setOffers] = useState();
  const [offerItems, setOfferItems] = useState(null);
  const [statesInChannel, setStatesInChannel] = useState([]);
  const [statusChange, setStatusChange] = useState(false);
  const [sUser, setSuser] = useState();
  const [userExists, setUserExists] = useState({exists: true, status: "loading"});
  const [apiKeysExist, setApiKeysExist] = useState({exists: true, status: "loading"});
  const [apiKeysCheck, setApiKeysCheck] = useState(true);
  const [apiKeysErrorMessage, setApiKeysErrorMessage] = useState("");
  const [usersItems, setUsersItems] = useState();
  const [users, setUsers] = useState();
  const [roles, setRoles] = useState();
  const [channels, setChannels] = useState();
  const [channelExists, setChannelExist] = useState(false);
  const [suErrorMessage, setSuErrorMessage] = useState("");
  const [categories, setCategories] = useState();
  const { id } = useParams();
  let navigate = useNavigate();
  const { addSnack, addToast } = useNotify();
  const [submittingUserCheck, setSubmittingUserCheck] = useState(true);
  const [timer, setTimer] = useState(null);
  const [isOpen, toggleOpen] = React.useState(false);
  const [previewCurrent, setPreviewCurrent] = useState();
  const [previewUpdate, setPreviewUpdate] = useState();
  const [saveNoteAfterEmail, setSaveNoteAfterEmail] = useState();
  const rendersCount = useRendersCount();

  useEffect(() => {
    if (formState.id !== "" && statusChange) {
     addRequest();
    }
  // eslint-disable-next-line
  }, [formState.status, statusChange]);

  useEffect(() => {
    if (id !== 'new') {
      fetchRequest(id);
    } else if (id === 'new') {
      clearForm();
    }
    // eslint-disable-next-line
  }, [id]);

  useUpdateEffect(() => {
    if (rendersCount < 4) return;
    if (formState && formState.apiKeys && formState.offer && formState.clientId && formState.status && formState.states) {
      addRequest();
    }
    // eslint-disable-next-line
  }, [formState.offer, formState.status, formState.states, formState.apiKeys]);

  useEffect(() => {
    if (formState && formState.apiKeys && formState.category && formState.clientId) {
      getOffersByCategory(formState.clientId);
    }
    // eslint-disable-next-line
  }, [formState.category]);

  useEffect(() => {
    if (formState && formState.apiKeys && formState.clientId) {
      getRoles(formState.clientId);
      getChannels(formState.clientId);
      getUserByEmail(formState.clientId, formState.submittingUserEmail);
      getOffersByCategory(formState.clientId);
      getUsers(formState.clientId);
    }
    // eslint-disable-next-line
  }, [formState.apiKeys]);

  useEffect(() => {
    if (formState && formState.apiKeys && formState.clientId && formState.submittingUserEmail && submittingUserCheck) {
      getUserByEmail(formState.clientId, formState.submittingUserEmail);
      setSubmittingUserCheck(false);
    }
        // eslint-disable-next-line
  }, [formState.submittingUserEmail, submittingUserCheck])

  useEffect(() => {
    if (formState && formState.apiKeys && formState.clientId && formState.channel) {
      checkChannelExists();
    }
    // eslint-disable-next-line
  }, [channels, userExists, formState.channel])

    useEffect(() => {
    if (formState && formState.status && formState.clientId && formState.accountName
          && formState.apiKeys && formState.salesforce && formState.notes &&
          formState.notes === saveNoteAfterEmail) {
      updateRequest();
    }
    // eslint-disable-next-line
  }, [saveNoteAfterEmail, formState.notes])

  const setInputUser = (value) => {
    const user = users.find(element => element.id === value);
    if (user && user.email !== formState.submittingUserEmail) {
      setFormState({...formState, submittingUserEmail: user.email, submittingUserFname: user.firstName, submittingUserLname: user.lastName, submittingUserPhone: phoneDigits(user.phoneNumber.number) })
      getUserById(formState.clientId, value);
    }
  }

  useEffect(() => {
    if (formState && formState.apiKeys && formState.clientId && formState.bundleClientId && apiKeysCheck) {
      findApiKeys(formState.bundleClientId);
      setApiKeysCheck(false);
    }
    // eslint-disable-next-line
  }, [formState.bundleClientId, apiKeysCheck])

  const setInput = (key, value) => {
    if (key === "category") {
      setFormState({ ...formState, [key]: value, offer: "" });
    } else if (key === "integratorId") {
      setFormState({ ...formState, [key]: value, referenceId: "integratorId" });
    } else if (key === "status") {
      setFormState({ ...formState, [key]: value })
    } else {
      setFormState({ ...formState, [key]: value });
    }
  }

  const addASnack = (status, message) => {
    if (status === "success") {
      addSnack({ variant: 'success', message: `${message} saved!` });
    } else {
      addSnack({ variant: 'danger', message: `Error saving ${message}!` });
    }
  }

    const findApiKeys = async (clientId) => {
    try {
    const myInit0 = {
        queryStringParameters: {
          clientId: clientId
        },
      };
      // find API keys
      await API.get("developerApi", "/findApiKeys", myInit0)
      .then((response) => {
        if (response.length > 0) {
            setApiKeysExist({exists: true, status: "ready"});
            console.log(response);
        } else {
            setApiKeysExist({exists: false, status: "not ready"});
            setApiKeysErrorMessage("Couldn't find API keys in SSM");
        }
        setSpinner(false);
      },
      (err) => {
        console.log("Error getting API Keys", err);
        addSnack({ variant: 'danger', message: `Couldn't find keys in SSM!` });
        setSpinner(false);
      })
    } catch (err) {
      console.log("error getting categories", err);
      setSpinner(false);
      return false;
    }
  }

  const clearForm = () => {
    setFormState(initialState);
  }

  async function fetchRequest(id) {
    if (!id && id === "") return;
    try {
      const reqData = await API.graphql(
        graphqlOperation(getRequests, { id: id })
      );
      const req = reqData.data.getRequests;
      if (req) {
        loadRequest(req);
      }
    } catch (err) {
      console.log("error fetching Request", err);
    }
  }

  const loadRequest = (req) => {
    console.log(req);
    const reqState = {
      id: req.id,
      clientId: req.clientId,
      status: req.status,
      accountName: req.accountName,
      salesforce: req.salesforce,
      offer: req.offer,
      states: req.states ? JSON.parse(req.states) : "",
      offersRequested: req.offersRequested,
      loanAmount: req.loanAmount,
      paymentAmount: req.paymentAmount,
      calculatorType: req.calculatorType,
      integratorId: req.integratorId ? req.integratorId : "",
      redirectUrl: req.redirectUrl ? req.redirectUrl: "",
      channel: req.channel,
      category: req.category,
      sendEmail: req.sendEmail,
      referenceId: req.referenceId,
      submittingUserFname: req.submittingUser.firstName,
      submittingUserLname: req.submittingUser.lastName,
      submittingUserEmail: req.submittingUser.email,
      submittingUserPhone: req.submittingUser.phone,
      submittingUserPassword: "",
      contactEmail: req.contactEmail,
      partnerEmail: req.partnerEmail,
      username: "",
      password: "",
      updateKeys: false,
      apiKeys: req.apiKeys,
      notes: req.notes,
      requestNotes: req.requestNotes,
      bundleClientId: req.bundleClientId
    };
    setFormState(reqState);
    console.log(reqState);
  };

const categoryName = (cat) => {
  if (cat === "HOME_IMPROVEMENT") return "HOME IMPROVEMENT";
  else if (cat === "SOLAR") return "SOLAR";
  else if (cat === "BATTERY") return "BATTERY";
  else if (cat === "ROOF") return  "ROOFING / WINDOWS / DOORS / SIDING / GEOTHERMAL";
}

const categoryCode = (cat) => {
  if (cat === "HOME IMPROVEMENT") return "HOME_IMPROVEMENT";
  else if (cat === "SOLAR") return "SOLAR";
  else if (cat === "BATTERY") return "BATTERY";
  else if (cat === "ROOFING / WINDOWS / DOORS / SIDING / GEOTHERMAL") return  "ROOF";
}

const checkChannelExists = () => {
  if (channels) {
    const channel = channels.find(ch => ch.name === formState.channel);
    if (channel) {
      setChannelExist(true);
      loadStates(channel);
    } else {
      setChannelExist(false);
    }
  }
}

const loadStates = (channel) => {
  setStatesInChannel(channel.states);
}

const editUser = async () => {
    if (channels && formState.channel && channelExists && sUser) {
      let userExistingChannels = [];
      userExistingChannels = sUser.channels.map((channel) => {
        return [
        ...userExistingChannels,
        `${channel.code}`
        ]
      });
      const channel = formState.channel;
      const states = channels.find(ch => ch.name === channel).states;
      let channelsMap = []
      channelsMap = states.map((st) => {
          return [
              ...channelsMap,
              `${st}_${channel}`
          ]
      });
      const allChannels = [...userExistingChannels.flat(), ...channelsMap.flat()];
      try {
        const payload = JSON.stringify({
          channels: allChannels
        });
        setSpinner(true);
        const myInit = {
          body: { data: payload || {} },
          queryStringParameters: {
            apiId: `${formState.clientId}ApiId`,
          apiKey: `${formState.clientId}ApiKey`,
          clientId: formState.clientId,
            userId: sUser.id
          },
        };
        await API.post("developerApi", "/user-channels", myInit);
        getUserByEmail(formState.clientId, formState.submittingUserEmail);
      } catch (err) {
        setSpinner(false);
        console.log("error updating channels", err);
      }

    } else {
      addSnack({ variant: 'warning', message: `Make sure channel exists in client config and submitting user is ready for share-app!` });
    }
  }

const getUserById = async (clientId, userId) => {
  try {
    // get userById
    const myInit1 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          userId: userId
        },
      };
    await API.get("developerApi", "/users", myInit1)
    .then(async (response) => {
      if (response) {
          const userById = response;
          setSuser(userById);
          console.log(userById);
          if (validRoles.find(role => role === userById.role.name) === undefined) {
              setUserExists({exists: false, status: "invalid role"});
              setSuErrorMessage(`User exists but ${userById.role.name} is not a valid role`);
              setSpinner(false);
              return;
          }
          const channels = userById.channels;
          if (channels.find(({ name }) => name.includes(formState.channel)) === undefined) {
              setUserExists({exists: false, status: "channel rights"});
              setSuErrorMessage(`User exists but needs ${formState.channel} channel rights`);
              setSpinner(false);
              return;
          }
          setUserExists({exists: true, status: "ready"});
      } else {
          setUserExists({exists: false, status: "undefined"});
          setSuErrorMessage("Submitting User doesn't exist");
      }
    })
  } catch (err) {
    console.log("error getting user by id", err);
    setSpinner(false);
    return false;
  }
}

const getUserByEmail = async (clientId, suemail) => {
    try {
      setSpinner(true);
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId,
          email: encodeURIComponent(suemail)
        },
      };

      if (!email(suemail)) {
        setUserExists({exists: false, status: "invalid email"});
        setSuErrorMessage("Not a valid Email");
        setSpinner(false);
        return;
      }

      // get user by Email
      await API.get("developerApi", "/users", myInit0)
      .then(async (response) => {
        if (response.data.length > 0) {
          // get userById
          const myInit1 = {
              queryStringParameters: {
                apiId: `${clientId}ApiId`,
                apiKey: `${clientId}ApiKey`,
                clientId: clientId,
                userId: response.data[0].id
              },
            };
          await API.get("developerApi", "/users", myInit1)
          .then(async (response) => {
            if (response) {
                const userById = response;
                setSuser(userById);
                if (validRoles.find(role => role === userById.role.name) === undefined) {
                    setUserExists({exists: false, status: "invalid role"});
                    setSuErrorMessage(`User exists but ${userById.role.name} is not a valid role`);
                    setSpinner(false);
                    return;
                }
                const channels = userById.channels;
                if (channels.find(({ name }) => name.includes(formState.channel)) === undefined) {
                    setUserExists({exists: false, status: "channel rights"});
                    setSuErrorMessage(`User exists but needs ${formState.channel} channel rights`);
                    setSpinner(false);
                    return;
                }
                setUserExists({exists: true, status: "ready"});
            } else {
                setSuser();
                setUserExists({exists: false, status: "undefined"});
                setSuErrorMessage("Submitting User doesn't exist");
            }
          })
        } else {
          setSuser();
          setUserExists({exists: false, status: "undefined"});
          setSuErrorMessage("Submitting User doesn't exist");
        }
        setSpinner(false);
      },
      (err) => {
        addSnack({ variant: 'danger', message: `Error getting users!` });
        console.log("Error getting users", err);
        setSpinner(false);
      })

    } catch (err) {
      console.log("error getting users", err);
      setSpinner(false);
      return false;
    }
  };

const getRoles = async (clientId) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get roles
      await API.get("developerApi", "/roles-channels", myInit0)
      .then(async (response) => {
        if (response.roles.length > 0) {
            setRoles(response.roles.filter(role => validRoles.find(vr => vr === role.name)));
        }
      },
      (err) => {
        console.log("Error getting roles", err);
      })

    } catch (err) {
      console.log("error getting roles", err);
      return false;
    }
  }

const getUsers = async (clientId) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get users
      await API.get("developerApi", "/users", myInit0)
      .then(async (response) => {
          if (response.data.length > 0) {
            setUsers(response.data);
            let usersArrayOptions = [];
            usersArrayOptions = response.data
              .map((element) => [
                ...usersArrayOptions,
                { name: `${element.email}`, value: element.id },
              ])
              .flat();
            setUsersItems(usersArrayOptions);
          }
      },
      (err) => {
        console.log("Error getting users", err);
      })

    } catch (err) {
      console.log("error getting users", err);
      return false;
    }
  }

const getChannels = async (clientId) => {
    try {
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get channels
      await API.get("developerApi", "/channels", myInit0)
      .then(async (response) => {
          if (response) {
            setChannels(response)
          }
      },
      (err) => {
        console.log("Error getting channels", err);
      })

    } catch (err) {
      console.log("error getting channels", err);
      return false;
    }
  }

const getOffersByCategory = async (clientId) => {
    try {
      setSpinner(true);
      const myInit0 = {
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // get categoryId
      await API.get("developerApi", "/categories", myInit0)
      .then(async (response) => {
          if (response.length > 0) {
              setCategories(response);
              const cat = response.find(el => el.name === categoryName(formState.category));
            const myInit1 = {
              queryStringParameters: {
                apiId: `${clientId}ApiId`,
                apiKey: `${clientId}ApiKey`,
                clientId: clientId,
                categoryId: cat.id,
                env: env
              },
            };

            // get offersByCategory
            await API.get("developerApi", "/offersByCategory", myInit1)
            .then((response) => {
              if (response.data.length > 0) {
               console.log("OFFERS", response.data)
               setOffers(response.data);
               let offersArrayOptions = [];
               offersArrayOptions = response.data
                  .map((element) => [
                    ...offersArrayOptions,
                    { name: element.productCode.includes("DEFERRED") ? `${element.productName} - ${element.name}` : `${element.name} - ${element.productName}`, value: element.id },
                  ])
                  .flat();
               setOfferItems(offersArrayOptions);
              }
            })
          }
        setSpinner(false);
      },
      (err) => {
        addSnack({ variant: 'danger', message: `Error getting offers!` });
        console.log("Error getting offers", err);
        setSpinner(false);
      })

    } catch (err) {
      addSnack({ variant: 'danger', message: `Error getting offers, check that Category is correct!` });
      console.log("Error getting offers", err);
      setSpinner(false);
      return false;
    }
  };

  const buildApp = (app, update) => {
    if (update)
      return {
        id: app.id !== "" ? app.id : "",
        clientId: app.clientId,
        status: app.status,
        accountName: app.accountName,
        salesforce: app.salesforce,
        offer: app.offer,
        states: JSON.stringify(app.states.replace(/\s/g, "")),
        offersRequested: app.offersRequested,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        paymentAmount: app.paymentAmount,
        calculatorType: app.calculatorType,
        integratorId: app.integratorId,
        redirectUrl: app.redirectUrl,
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        referenceId: app.referenceId,
        contactEmail: app.contactEmail,
        partnerEmail: app.partnerEmail,
        apiKeys: app.apiKeys,
        notes: app.notes,
        bundleClientId: app.bundleClientId
      };
    else
      return {
        clientId: app.clientId,
        status: app.status,
        accountName: app.accountName,
        salesforce: app.salesforce,
        offer: app.offer,
        states: JSON.stringify(app.states.replace(/\s/g, "")),
        offersRequested: app.offersRequested,
        channel: app.channel,
        category: app.category,
        loanAmount: app.loanAmount,
        paymentAmount: app.paymentAmount,
        calculatorType: app.calculatorType,
        integratorId: app.integratorId,
        redirectUrl: app.redirectUrl,
        submittingUser: {
          firstName: app.submittingUserFname,
          lastName: app.submittingUserLname,
          email: app.submittingUserEmail,
          phone: app.submittingUserPhone,
        },
        sendEmail: app.sendEmail,
        referenceId: app.referenceId,
        contactEmail: app.contactEmail,
        partnerEmial: app.partnerEmail,
        apiKeys: app.apiKeys,
        notes: app.notes,
        bundleClientId: app.bundleClientId
      };
  };

  const validateRequest = (formState) => {
    let error = "";
      if (!formState.status) error += "[Status]";
      if (!formState.accountName) error += " [Account Name]";
      if (!formState.category) error += " [Category]";
      // if (!formState.submittingUserEmail) error += " [Submitting User Email]";
      if (error !== "") {
        addSnack({ variant: 'danger', message: `Please enter ${error}` });
        setSpinner(false);
        return false;
      }
      return true;
  }

  const checkRequestExist = async (accountName) => {
  try {
      const appData = await API.graphql(
        graphqlOperation(appsByAccountName, { accountName: accountName })
      );
      const apps = appData.data.appsByAccountName.items;
      if (apps && apps.length > 0) {
        addSnack({ variant: 'danger', message: `Request with same Account Name already exists!` });
        setSpinner(false);
        return true;
      }
      return false;
    } catch (err) {
      console.log("error fetching Request", err);
    }
  }

  const updateRequest = async () => {
    const app = buildApp({...formState, apiKeys: true}, true);
    await API.graphql(graphqlOperation(updateRequests, { input: app }));
    addSnack({ variant: 'success', message: 'Request saved successfully!' });
    const st = {
      ...formState,
      username: "",
      password: "",
      apiKeys: true
    }
    setFormState(st);
  }


  const updateNotesRequest = async (message) => {
    let note = formState.notes;
    if (note !== null && note.trim() !== "") note = note.concat("\n");
    setFormState({ ...formState, notes: note.concat(dateTimeStr(Date.now())).concat(" - " + message) });
    setSaveNoteAfterEmail(note.concat(dateTimeStr(Date.now())).concat(" - " + message));
  }

  const updateApp = ()  => {
    //TODO: Update SharedApp
    console.log("update app");
  }

  async function fetchSharedApp(id) {
    if (!id && id === "") return;
    try {
      const appData = await API.graphql(
        graphqlOperation(appsByClientId, { clientId: id })
      );
      const apps = appData.data.appsByClientId.items;
      if (apps && apps.length > 0) {
        const app = apps[0];
        const payload = buildShareApp(app);
        setPreviewCurrent(app);
        setPreviewUpdate(payload);
        toggleOpen(true);
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
      addSnack({ variant: 'danger', message: `Error updating QuickLink!` });
    }
  }

  const buildShareApp = (app) => {
      return {
        id: app.id,
        clientId: app.clientId,
        status: app.status,
        partnerName: app.partnerName,
        channel: formState.channel,
        category: formState.category,
        loanAmount: formState.loanAmount,
        paymentAmount: formState.paymentAmount,
        calculatorType: formState.calculatorType,
        integratorId: formState.integratorId,
        redirectUrl: formState.redirectUrl,
        submittingUser: {
          firstName: formState.submittingUserFname,
          lastName: formState.submittingUserLname,
          email: formState.submittingUserEmail,
          phone: formState.submittingUserPhone,
        },
        sendEmail: formState.sendEmail,
        displayIframe: app.displayIframe,
        referenceId: formState.referenceId,
        formUrl: app.formUrl,
        bundleClientId: app.bundleClientId
      };
  }

  async function storeKeys() {
    try {
      setSpinner(true);
      if (!formState.clientId) {
        addSnack({ variant: 'danger', message: `Need client id to store API Keys!` });
        setSpinner(false);
        return true;
      }
      if (formState.username !== "" && formState.password !== "") {
        const myInit = {
          queryStringParameters: {
            secureString: `${formState.username}:${formState.password}`,
            clientId: formState.clientId,
            overwrite: formState.updateKeys,
            env: env
          },
        };
        await API.post("developerApi", "/storeKeys", myInit);
        addSnack({ variant: 'success', message: 'API keys stored successfully!' });
        updateRequest();
      } else {
        addSnack({ variant: 'danger', message: `Enter API username and password!` });
        setSpinner(false);
        return true;
      }
    } catch (err) {
      console.log("Error Storing Keys:", err);
      addSnack({ variant: 'danger', message: 'Error Storing Keys' });
    }
    setSpinner(false);
  }

  async function addRequest() {
    try {
      setSpinner(true);
      if (!validateRequest(formState)) return;
      const app = buildApp(formState, formState.id !== "");
      if (formState.id !== "") {
        await API.graphql(graphqlOperation(updateRequests, { input: app }));
         addSnack({ variant: 'success', message: 'Request saved successfully!' });
      } else {
        const exists = await checkRequestExist(formState.accountName);
        if (exists && formState.id === "") return;
        const result = await API.graphql(
          graphqlOperation(createRequests, { input: app })
        );
        const updatedReq = buildApp(formState, true);
        updatedReq.id = result.data.createRequests.id;
        await API.graphql(
          graphqlOperation(updateRequests, { input: updatedReq })
        );
         addSnack({ variant: 'success', message: 'Request created successfully!' });
         loadRequest(updatedReq);
         navigate(`../request/${updatedReq.id}`, { replace: false });
      }
    } catch (err) {
      console.log("Error saving request:", err);
      addSnack({ variant: 'danger', message: 'Error saving Request' });
    }
    setStatusChange(false);
    setSpinner(false);
  }

  const validateUserRequest = (formState) => {
    let error = "";
      if (!formState.submittingUserEmail) error += "[Email]";
      if (!formState.submittingUserFname) error += " [First Name]";
      if (!formState.submittingUserLname) error += " [Last Name]";
      if (!formState.submittingUserRole) error += " [Role]";
      if (error !== "") {
        addSnack({ variant: 'danger', message: `Please enter Submitting User ${error}` });
        setSpinner(false);
        return false;
      }
      if (!channelExists) {
        addSnack({ variant: 'danger', message: `Please Create Offer Channel in client-config` });
        setSpinner(false);
        return false;
      }
      return true;
  }

  const createUser = async () => {
    try {
      setSpinner(true);

      if (!validateUserRequest(formState)) return;
      const clientId = formState.clientId;
      const randomPassword = generatePassword(12);
      const password = formState.submittingUserPassword === "" ? randomPassword : formState.submittingUserPassword;
      const myInit0 = {
        body: { data: {
          "firstName": formState.submittingUserFname,
          "lastName": formState.submittingUserLname,
          "password": password,
          "email": formState.submittingUserEmail,
          "mobilePhone": formState.submittingUserPhone,
          "roleId": formState.submittingUserRole
        }},
        queryStringParameters: {
          apiId: `${clientId}ApiId`,
          apiKey: `${clientId}ApiKey`,
          clientId: clientId
        },
      };

      // post create user
      await API.post("developerApi", "/create-user", myInit0)
      .then(async (response) => {
          if (response.id) {
            addSnack({ variant: 'success', message: `Success created user!` });
            updateNotesRequest("### New User Created, Username: " + formState.submittingUserEmail + " Password: " + password +" ###");
            getUserById(formState.clientId, response.id);
          } else {
            setSpinner(false);
          }
      },
      (err) => {
        addSnack({ variant: 'danger', message: `Error creating user!` });
        console.log("Error creating user", err);
        setSpinner(false);
      })
    } catch (err) {
      console.log("error creating user", err);
      setSpinner(false);
      return false;
    }
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      addToast({ variant: 'success', message: `Copied to Clipboard!` });
    } catch (err) {
      err.log("Failed to copy!");
    }
  };

const createQuickLink = async () => {
  navigate(`../edit/request/${formState.id}`, { replace: false });
}

const editQuickLink = async () => {
  if (!formState.clientId && formState.clientId === "") return;
  navigate(`../edit/${formState.clientId}`, { replace: false });
}

const updateQuickLink = async () => {
  if (!formState.clientId && formState.clientId === "") return;
  fetchSharedApp(formState.clientId);
}

const sendEmailToRequester = async () => {
  try {
    setSpinner(true);
    if (formState.apiKeys && formState.id !== "" && formState.contactEmail !== "" && formState.category !== "" && formState.accountName !== "") {
      const myParams = {
        queryStringParameters: {
          accountName: formState.accountName,
          id: formState.id,
          category: formState.category,
          contactEmail: formState.contactEmail,
          env: env
        },
      };
      await API.post("developerApi", "/sendEmailToRequester", myParams);
      addSnack({ variant: 'success', message: 'Email sent succesffully!' });
      updateNotesRequest("2nd Step email sent to requester: " + formState.contactEmail);
    } else {
      addSnack({ variant: 'danger', message: `Error sending email!` });
      setSpinner(false);
      return true;
    }
  } catch (err) {
    console.log("Error Sending Email:", err);
    addSnack({ variant: 'danger', message: 'Error Sending Email' });
  }
  setSpinner(false);
}

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Heading mb={3} variant="h3">
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <View>{id === 'new' ? 'New' : '' } Request Details </View>
                <SearchField
                  label="Search"
                  placeholder="search by Account Name..."
                  size="small"
                  onSubmit={(e) => fetchRequest(e)}
                  onClear={() => navigate(`../request/new`, { replace: false })}
                />
              </Flex>
            </Heading>
            <ReactCard>
              <Flex direction="row" alignItems="flex-end" justifyContent="space-between">
                <Box style={styles.statusBox}>
                  <SelectField
                    style={styles.input}
                    label="Status"
                    value={formState.status}
                    onChange={async (e) => {
                      setStatusChange(true);
                      setFormState({ ...formState, "status": e.target.value });
                    }}
                    size="small"
                    width="50%"
                  >
                    <option value="">Please select a Status</option>
                    <option value="NEW">{Status.NEW}</option>
                    <option value="WAITINGONAPIKEYS">{Status.WAITINGONAPIKEYS}</option>
                    <option value="READYFORREQUESTER">{Status.READYFORREQUESTER}</option>
                    <option value="NEWPLUS">{Status.NEWPLUS}</option>
                    <option value="INPROGRESS">{Status.INPROGRESS}</option>
                    <option value="READYFORCREATION">{Status.READYFORCREATION}</option>
                    <option value="UPDATEQUICKLINK">{Status.UPDATEQUICKLINK}</option>
                    <option value="BLOCKED">{Status.BLOCKED}</option>
                    <option value="COMPLETED">{Status.COMPLETED}</option>
                  </SelectField>
                  {/* <LumosButton sx={{marginLeft: "20px"}} onClick={() => addRequest}>
                    Save Request
                  </LumosButton> */}
                </Box>
                {formState && formState.id && formState.apiKeys && channelExists && userExists.status === "ready" && formState.status === "READYFORCREATION" && (
                  <LumosButton onClick={createQuickLink}>
                    Create QuickLink
                  </LumosButton>
                )}
                {formState && formState.id && formState.apiKeys && channelExists && userExists.status === "ready" && formState.status === "COMPLETED" && (
                  <LumosButton onClick={editQuickLink}>
                    Edit QuickLink
                  </LumosButton>
                )}
                {formState && formState.id && formState.apiKeys && channelExists && userExists.status === "ready" && formState.status === "UPDATEQUICKLINK" && (
                  <LumosButton onClick={updateQuickLink}>
                    Update QuickLink
                  </LumosButton>
                )}
                {formState && formState.id && formState.apiKeys && formState.status === "READYFORREQUESTER" && (
                  <LumosButton onClick={sendEmailToRequester}>
                    Send to Requester
                  </LumosButton>
                )}
              </Flex>
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("accountName", event.target.value)}
                size="small"
                value={formState.accountName}
                label="Account Name"
                style={styles.input}
                descriptiveText={"Please use exact name as in Salesforce account"}
              />
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput("salesforce", event.target.value)}
                size="small"
                value={formState.salesforce}
                label="Salesforce link"
                style={styles.input}
              />
            </ReactCard>
            <ReactCard>
              <SelectField
                style={styles.input}
                label="Category"
                value={formState.category}
                onChange={(e) => setInput("category", e.target.value)}
                size="small"
              >
                <option value="">Please select a Category</option>
                {categories ? (
                  categories.map(cat =>
                    <option key={cat.id} value={categoryCode(cat.name)}>{categoryCode(cat.name)}</option>
                  )
                ) : (
                <>
                <option value={Categories.HOME_IMPROVEMENT.cat}>{Categories.HOME_IMPROVEMENT.cat}</option>
                <option value={Categories.ROOF.cat}>{Categories.ROOF.cat}</option>
                <option value={Categories.SOLAR.cat}>{Categories.SOLAR.cat}</option>
                <option value={Categories.BATTERY.cat}>{Categories.BATTERY.cat}</option>
                </>
                )}
              </SelectField>
            </ReactCard>
            <ReactCard>
              <TextField
                onChange={(event) => setInput(`${formState.calculatorType === "PA" ? "paymentAmount" : "loanAmount"}`, event.target.value)}
                onBlurCapture={({target}) => {
                  setInput(`${formState.calculatorType === "PA" ? "paymentAmount" : "loanAmount"}`, moneyStringToNumber(target.value))
                }}
                size="small"
                style={styles.input}
                descriptiveText="Two point precision decimal."
                value={formState.calculatorType === "PA" ? formState.paymentAmount : formState.loanAmount}
                label={`Average ${formState.calculatorType === "PA" ? "Payment" : "Loan"}  Amount`}
              />
            </ReactCard>
            <LumosCard
                header={{
                  title: "Offer(s)",
                }}
                sx={{ marginTop: 20, marginBottom: 20 }}
              >
                <ReactCard>
                  <TextField
                    onChange={(event) => setInput("channel", event.target.value)}
                    size="small"
                    width="50%"
                    style={styles.inputError}
                    value={formState.channel}
                    errorMessage="Channel doesn't exist in client-config"
                    hasError = {!channelExists && formState.apiKeys}
                    label="Offer(s) channel"
                    descriptiveText={channelExists && (
                    <Text
                      as="span"
                      color="green.60"
                      fontStyle="italic"
                      fontSize="0.8rem"
                    >Channel exists in client-config</Text>
                    )}
                    innerEndComponent={
                      formState.channel && (
                      <FieldGroupIcon
                        ariaLabel="Check"
                        variation="link"
                        height="90%"
                      >
                        {channelExists  && (
                          <i className="gg-check-o-green"></i>
                        )}
                      </FieldGroupIcon>
                    )}
                  />
              </ReactCard>
                <ReactCard>
                  <Offers offersArray={formState.offer} offerItems={offerItems} active={offers} setInput={setInput} addSnack={addASnack} />
                </ReactCard>
                <ReactCard>
                  <Heading variant="h5" width="50%">Selected offers ids</Heading>
                  <Text
                    lineHeight="1.5em"
                    fontSize="1em"
                    width="50%"
                  >
                    {formState.offer.split(",").map((o) => (
                      <li key={o}>{o} <Icon variant="clipboard" size="sm" onClick={e =>copyToClipBoard(o)} sx={{cursor: "pointer", color: "green"}}/></li>
                    ))}
                  </Text>
                </ReactCard>
                <ReactCard>
                  <States statesArray={formState.states} statesItems={statesInChannel} active={statesInChannel.length !== 0} setInput={setInput} addSnack={addASnack} />
                </ReactCard>
            </LumosCard>
            <LumosCard
                header={{
                  title: "Submitting User",
                }}
                sx={{ marginTop: 20, marginBottom: 20 }}
              >
              <ReactCard>
                <Flex gap="1rem" direction="column">
                  <TextField
                    label="Submitting User Email"
                    errorMessage={suErrorMessage}
                    hasError = {!userExists.exists}
                    onChange={(event) => {
                      clearTimeout(timer);
                      setSubmittingUserCheck(false);
                      setInput("submittingUserEmail", event.target.value)
                      const newTimer = setTimeout(() => {
                        setSubmittingUserCheck(true);
                      }, 1000)
                      setTimer(newTimer)
                    }}
                    size="small"
                    style={styles.inputError}
                    value={formState.submittingUserEmail}
                    descriptiveText={formState.apiKeys && channelExists && userExists.status === "ready" && (
                    <Text
                      as="span"
                      color="green.60"
                      fontStyle="italic"
                      fontSize="0.8rem"
                    >Submitting User is ready to submit shared-app</Text>
                    )}
                    innerEndComponent={
                      formState.submittingUserEmail && (
                      <FieldGroupIcon
                        ariaLabel="Check"
                        variation="link"
                        height="90%"
                      >
                        {userExists.status === "ready"  && (
                          <i className="gg-check-o-green"></i>
                        )}
                      </FieldGroupIcon>
                    )}
                  />
                </Flex>
                {!userExists.exists && userExists.status === "channel rights" && (
                  <LumosButton sx={{marginTop: "10px", width: "250px"}} onClick={() => editUser()}>Add Channel to User</LumosButton>
                )}
              </ReactCard>
              <ReactCard>
                <Flex direction="row" alignItems="flex-start">
                  <TextField
                    label="Submitting User First Name"
                    onChange={(event) =>
                      setInput("submittingUserFname", event.target.value)
                    }
                    size="small"
                    width="50%"
                    style={styles.input}
                    value={formState.submittingUserFname}
                  />
                  <TextField
                    label="Submitting User Last Name"
                    onChange={(event) =>
                      setInput("submittingUserLname", event.target.value)
                    }
                    size="small"
                    width="50%"
                    style={styles.input}
                    value={formState.submittingUserLname}
                  />
                </Flex>
              </ReactCard>
              <ReactCard>
                <TextField
                  label="Submitting User Phone"
                  onChange={(event) =>
                    setInput("submittingUserPhone", event.target.value)
                  }
                  size="small"
                  width="50%"
                  descriptiveText="10 digits, no other characters"
                  style={styles.input}
                  value={formState.submittingUserPhone}
                />
              </ReactCard>
              {sUser &&
              <ReactCard>
                <Heading variant="h6" width="50%">Submitting User Channels</Heading>
                {sUser && (
                  sUser.channels.map(ch =>
                    <span key={ch.code} style={styles.tag}>
                      <Tag>{ch.name}</Tag>
                    </span>
                  )
                )}
              </ReactCard>
              }
              <Divider
                orientation="horizontal"
                style={styles.divider}
                size="small"
              />
              {roles && userExists.status === "undefined" &&
              (<>
              <ReactCard>
                <TextField
                    label="Submitting User Password"
                    descriptiveText="Default password of submitting user"
                    onChange={(event) =>
                      setInput("submittingUserPassword", event.target.value)
                    }
                    size="small"
                    width="50%"
                    style={styles.input}
                    placeholder="Cle@nEn3rgy!"
                    value={formState.submittingUserPassword}
                />
                </ReactCard>
                <ReactCard>
                  <SelectField
                    style={styles.input}
                    label="Submitting User Role"
                    value={formState.submittingUserRole}
                    onChange={(e) => setInput("submittingUserRole", e.target.value)}
                    size="small"
                    width="50%"
                  >
                    <option value=""></option>
                    {roles.map(role =>
                      <option key={role.id} value={role.id}>{role.name}</option>
                    )}
                  </SelectField>
                </ReactCard>
                <ReactCard>
                  <LumosButton
                      onClick={createUser}
                    >
                    Create User
                  </LumosButton>
                </ReactCard>
            </>)
            }
            <ReactCard>
              <Users usersItems={usersItems} setInput={setInputUser} sUser={sUser} users={users} />
            </ReactCard>
            </LumosCard>
            <ReactCard>
              <TextField
                label="Requester Email"
                onChange={(event) =>
                  setInput("contactEmail", event.target.value)
                }
                size="small"
                width="50%"
                style={styles.input}
                value={formState.contactEmail}
              />
           </ReactCard>
           <ReactCard>
              <TextField
                label="Partner Email"
                onChange={(event) =>
                  setInput("partnerEmail", event.target.value)
                }
                size="small"
                width="50%"
                style={styles.input}
                value={formState.partnerEmail}
              />
           </ReactCard>
           <ReactCard>
              <SelectField
                style={styles.input}
                label="Reference logic"
                value={formState.referenceId}
                onChange={(e) => setInput("referenceId", e.target.value)}
                size="small"
                width="50%"
              >
                <option value="quickLink">QuickLink</option>
                <option value="mobilePhone">Mobile Phone</option>
                <option value="lastName">Last Name</option>
                <option value="integratorId">Integrator Id</option>
                <option value="goodleapplus">GoodLeapPlus</option>
              </SelectField>
            </ReactCard>
            <ReactCard>
              <SwitchField
                isDisabled={false}
                style={styles.switch}
                label="Send Email"
                labelPosition="end"
                size="default"
                isChecked={formState.sendEmail}
                onChange={(event) => setInput("sendEmail", event.target.checked)}
              />
            </ReactCard>
            <ReactCard>
              <TextAreaField
                style={styles.input}
                label="Notes"
                size="default"
                onChange={(event) => setInput("notes", event.target.value)}
                rows="3"
                direction="column"
                value={formState.notes}
              />
            </ReactCard>
            <ReactCard>
              <TextAreaField
                style={styles.input}
                label="Requester Notes"
                variation="quiet"
                isDisabled={true}
                size="default"
                rows="3"
                direction="column"
                value={formState.requestNotes}
              />
            </ReactCard>
            <Divider
              orientation="horizontal"
              style={styles.divider}
              size="small"
            />
            <Collapsible title="Special Features">
            <LumosCard
                header={{
                  title: "Features for Corporate/Integrator accounts",
                }}
                sx={{ marginTop: 20, marginBottom: 20 }}
              >
              <ReactCard>
                <SelectField
                  style={styles.input}
                  label="Calculator Type"
                  value={formState.calculatorType}
                  onChange={(e) => setInput("calculatorType", e.target.value)}
                  size="small"
                  width="35%"
                  // descriptiveText="Do not change unless this is Daikin setup."
                >
                  <option value=""></option>
                  <option value="LA">Loan Amount</option>
                  <option value="PA">Payment Amount</option>
                </SelectField>
              </ReactCard>
              <ReactCard>
                <TextField
                  onChange={(event) => setInput("integratorId", event.target.value)}
                  size="small"
                  value={formState.integratorId}
                  label="Integrator Id"
                  style={styles.input}
                  // descriptiveText={"Important! Currently this only works for Daikin where calculator leads with payment amount."}
                />
              </ReactCard>
              <ReactCard>
                <TextField
                  onChange={(event) => setInput("redirectUrl", event.target.value)}
                  size="small"
                  value={formState.redirectUrl}
                  label="Redirect Url"
                  style={styles.input}
                  descriptiveText={"Important! This will only work if client is configured for this feature."}
                />
              </ReactCard>
              <ReactCard>
                <TextField
                onChange={(event) => {
                      clearTimeout(timer);
                      setApiKeysCheck(false);
                      setInput("bundleClientId", event.target.value)
                      const newTimer = setTimeout(() => {
                        setApiKeysCheck(true);
                      }, 1000)
                      setTimer(newTimer)
                    }}
                errorMessage={apiKeysErrorMessage}
                hasError = {!apiKeysExist.exists}
                size="small"
                value={formState.bundleClientId}
                label="GoodLeap Plus Bundle ClientId"
                style={styles.input}
                descriptiveText={formState.bundleClientId && apiKeysExist.exists && apiKeysExist.status === "ready" && (
                  <Text
                    as="span"
                    color="green.60"
                    fontStyle="italic"
                    fontSize="0.8rem"
                  >Blundle Client API keys are ready to use</Text>
                  )}
                innerEndComponent={
                  formState.bundleClientId && (
                  <FieldGroupIcon
                    ariaLabel="Check"
                    variation="link"
                    height="90%"
                  >
                    {apiKeysExist.status === "ready"  && (
                      <i className="gg-check-o-green"></i>
                    )}
                  </FieldGroupIcon>
                )}
              />
              {/* Check for API keys  */}
              </ReactCard>
            </LumosCard>
            </Collapsible>
            <LumosButton
                sx={{ marginBottom: "20px", marginTop: "10px" }}
                onClick={addRequest}
              >
              Save request
            </LumosButton>
            <Heading mb={2} mt={2} variant="h4">Admin Section</Heading>
            <Card variation="outlined" style={styles.adminSpace}>
            <Heading mb={3} variant="h5">Store API keys in the Parameter Store</Heading>
            <TextField
              onChange={(event) => setInput("clientId", event.target.value)}
              descriptiveText="This id will be used to store the API keys in the Parameter store and will be part of the URL, needs to be unique."
              size="small"
              value={formState.clientId}
              label="Client Id"
              style={styles.inputWhite}
              isDisabled={formState.apiKeys ? true : false}
            />
            {formState.apiKeys && formState.id !== "" && (
              <Alert
                variation="success"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys in SSM"
                style={styles.mb}
              ></Alert>
            )}
            {!formState.apiKeys && formState.id !== "" && formState.clientId !== "" && (
              <>
              <Alert
                variation="warning"
                isDismissible={false}
                hasIcon={true}
                heading="API Keys NOT in SSM"
                style={styles.mb}
              ></Alert>
              <LumosButton variant="secondary" onClick={() => { setInput("apiKeys", true); addRequest(); }}
                sx={{marginBottom: "15px"}}
              >
                API keys already stored
              </LumosButton>
             </>
            )}
            <LumosCard
                    header={{
                      title: "Store API Keys",
                    }}
                  >
                  <PasswordField
                    onChange={(event) => setInput("username", event.target.value)}
                    size="small"
                    style={styles.inputPassword}
                    value={formState.username}
                    label="Username"
                    isDisabled={formState.clientId === "" ? true : false}
                  />
                  <Divider
                    orientation="horizontal"
                    style={styles.divider}
                    size="small"
                  />
                  <PasswordField
                    onChange={(event) => setInput("password", event.target.value)}
                    size="small"
                    style={styles.inputPassword}
                    value={formState.password}
                    label="Password"
                    isDisabled={formState.clientId === "" ? true : false}
                  />
                  {formState.apiKeys &&
                  <SwitchField
                    isDisabled={false}
                    style={styles.switch}
                    label="Update API Keys"
                    labelPosition="end"
                    size="default"
                    isChecked={formState.updateKeys}
                    onChange={(event) => setInput("updateKeys", event.target.checked)}
                  />
                  }
                  <Divider
                    orientation="horizontal"
                    style={styles.divider}
                    size="small"
                  />
                  <LumosButton onClick={() => storeKeys()}
                    sx={{marginTop: "5px"}}
                  >
                    Store keys
                  </LumosButton>
            </LumosCard>
            </Card>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
          <UpdatePreview isOpen={isOpen} updateApp={updateApp} toggleOpen={toggleOpen} previewCurrent={previewCurrent} previewUpdate={previewUpdate} />
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    width: 960,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "20px 20px"
  },
  green: {
    color: "#green"
  },
  divider: { margin: "5px 0", color: "white", borderColor: "white" },
  divider2: { margin: "10px 0", color: "white", borderColor: "white" },
  space: { marginTop: "20px" },
  mb: { marginBottom: "20px" },
  mx: { margin: "20px 0"},
  statusBox: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "start"
  },
  tag: {
    margin: "20px 10px"
  },
  adminSpace: {
    marginBottom: "20px",
    backgroundColor: "#e8e8e8"
  },
  app: { marginBottom: 15 },
  input: {
     marginBottom: 10,
    padding: 8,
    fontSize: 16
  },
  inputError : {
    padding: 8,
    fontSize: 16
  },
  inputPassword : {
    padding: 8,
    fontSize: 16
  },
  inputWhite: {
    marginBottom: 20,
    padding: 8,
    fontSize: 16,
    backgroundColor: "white"
  },
  switch: {
    fontSize: 16,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
     marginBottom: "20px"
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
    fontSize: "13px"
  }
};

export default Request;
